import React from 'react'
import { Col, Row } from 'reactstrap'
import BookingStatus from './BookingStatus'
import JobStatus from './JobStatus'
import DefaultWorkingHours from './DefaultWorkingHours'

function DropDownConfig({ entityDetail }) {
    return (
        <Row>
            <Col md={4} className='mb-5'>
                <BookingStatus />
            </Col>
            <Col md={4}>
                <JobStatus />
            </Col>
            <Col md={4}>
                <DefaultWorkingHours entityDetail={entityDetail} />
            </Col>
        </Row>
    )
}

export default DropDownConfig