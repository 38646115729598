import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row, Spinner } from 'reactstrap'
import { SettingsService } from '../../../modules/Services/Settings';
import toast from 'react-hot-toast';

function InvoiceNumbering() {
    const entity_id = localStorage.getItem('entity_id');
    const [loader, setLoader] = useState('');
    const [invoiceSetting, setInvoiceSetting] = useState(null);

    useEffect(() => {
        getInvoiceSetting()
    }, [])

    const getInvoiceSetting = () => {
        SettingsService.getInvoiceSetting(entity_id)
        .then((res: any) => {
            setInvoiceSetting(res?.data?.response?.invoice_config ? JSON.parse(res?.data?.response?.invoice_config) : null)
        })
        .catch((err: any) => console.log('err 21', err?.response?.data?.message))
    }

    const updateInvoiceSetting = () => {
        setLoader('update')
        const data = {
            entity_id: entity_id,
            invoice_config: invoiceSetting
        }
        SettingsService.updateInvoiceSetting(entity_id, data)
        .then((res: any) => {
            toast.success(res?.data?.message)
        })
        .catch((err: any) => toast.error(err?.response?.data?.message))
        .finally(() => setLoader(''))
    }

    const handleEveant = (field: any, value: any) => {
        setInvoiceSetting((pre: any) => ({...pre, [field]: value}))
    }

    return (
        <Fragment>
            <Card className='mt-3'>
                <CardHeader style={{minHeight: '50px'}}>
                    <CardTitle className='dropdown-header'>Invoice Numbering</CardTitle>
                </CardHeader>
                <CardBody className='p-5'>
                    <div className='d-flex'>
                        <div className='d-flex align-items-center me-5'>
                            <Label className='m-0'>Prefix</Label>
                        </div>
                        <div>
                            <Input type='text'
                                placeholder='Enter Prefix'
                                className='form-control-sm'
                                value={invoiceSetting?.prefix}
                                onChange={(e) => handleEveant('prefix', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='d-flex mt-5'>
                        <div className='d-flex align-items-center me-5'>
                            <Label className='m-0'>Next Invoice Number</Label>
                        </div>
                        <div>
                            <Input type='text'
                                placeholder='Enter Number'
                                className='form-control-sm w-85'
                                value={invoiceSetting?.next_invoice_number}
                                onChange={(e) => handleEveant('next_invoice_number', e.target.value)}
                            />
                        </div>
                    </div>
                    <Row className='mt-5'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" color="primary" className='btn btn-sm' disabled={loader === 'update'} onClick={() => updateInvoiceSetting()}>
                                {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Update</span>}
                            </Button>
                        </div>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default InvoiceNumbering