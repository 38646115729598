import React, { useState, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { JobService } from '../../modules/Services/Job';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller, useForm } from 'react-hook-form';
import UILoader from '../../modules/commonModel/Ui-loader';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';
import ReactSelect from 'react-select';
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import { CustomerService } from '../../modules/Services/Customers';

function ConfirmationEmail({ openConfirmationEmail, setOpenConfirmationEmail, currentCustomer }) {
    const entity_id = localStorage.getItem('entity_id')
    const { control, reset, clearErrors, handleSubmit, formState: { errors } } = useForm()
    const { id } = useParams()
    const [loader, setLoader] = useState(null)
    const [editorHeading, setEditorHeading] = useState({ to: '', subject: '', cc: '' })
    const [editorValue, setEditorValue] = useState('<p>Dear Cyril Byrne,</p><p>Thank you for your booking. Please check the details we have you booked in for below:</p><p><br></p><h4>Booking Confirmation A0053</h4><h4><br></h4><h4><br></h4><h4>Wednesday, January 08, 2025</h4><p><strong>09:00</strong> Gorey Train Station to Varoom HQ</p><p><strong>17:00</strong> Varoom HQ to Gorey Train Station</p><p><br></p><p><br></p><p>Notes:</p><p>Rate: <strong>€600</strong></p><p><br></p><p>If there are any issues with the booking details, please let us know.</p><p><br></p><p>Regards,</p><p>The Team @ Tony Doyle Coaches Ltd..</p><p>Tel: 01 2018600</p><p>Web: <a href="www.tonydoyle.com" rel="noopener noreferrer" target="_blank">www.tonydoyle.com</a></p>');
    const [emailClientOption, setEmailClientOption] = useState([])
    const [currentClientEmail, setCurrentClientEmail] = useState({ value: null, label: 'Select Client', email: '', name: '', is_contact: null })

    const submitEmail = () => {
        setLoader('create')
        const data = {
            entity_id: entity_id,
            booking_id: id,
            to: editorHeading?.to,
            cc: editorHeading?.cc,
            subject: editorHeading?.subject,
            mail_body: editorValue
        }
        JobService.confirmationEmail(entity_id, data)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setOpenConfirmationEmail(false)
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(null));
    }

    const getConfirmationEmail = () => {
        setLoader('loading')
        JobService.getEmailTemplate(entity_id, { booking_id: id })
            .then((res: any) => {
                setEditorHeading({ to: res?.data?.response?.to, cc: '', subject: res?.data?.response?.subject })
                setEditorValue(res?.data?.response?.mail_body)
                reset({ to: res?.data?.response?.to })
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(null));
    }

    useEffect(() => {
        if (openConfirmationEmail) {
            getClientEmailList()
            getConfirmationEmail()
        } else {
            setEditorValue('')
            clearErrors()
        }
    }, [openConfirmationEmail])

    const handleConfig = (field: any, value: any) => {
        setEditorHeading((pre: any) => ({ ...pre, [field]: value }))
    }

    const getClientEmailList = () => {
        const option = [];
        option.push({ value: currentCustomer?.value, label: currentCustomer?.email + ' (Client Email)', email: currentCustomer?.email, name: currentCustomer?.label, is_contact: 0 })

        CustomerService.getCustomeContactsList(entity_id, currentCustomer?.value, {})
        .then((res: any) => {

            res.data?.response?.map((item: any) => {
                option.push({ value: item?.id, label: item?.contact_email + ' (' + item?.contact_role + ')', email: item?.contact_email, name: item?.contact_name, is_contact: 1 })
            })
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
        setEmailClientOption(option)
    }

    return (
        <Modal isOpen={openConfirmationEmail} toggle={() => setOpenConfirmationEmail(false)} className={`modal-dialog-centered modal-lg`}>
            <UILoader blocking={loader === 'loading'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'loading' ? 'loader-blur' : ''}>
                <Form onSubmit={handleSubmit(submitEmail)}>
                    <ModalBody>
                        <Row className='d-flex align-items-center'>
                            <Col md='3'>
                                <label className='form-label mb-0'>Client Contact</label>
                            </Col>
                            <Col md='9'>
                                <ReactSelect
                                    isClearable={false}
                                    className='react-select w-100'
                                    classNamePrefix='select'
                                    placeholder='Select Status'
                                    styles={customSelectStyles(null)}
                                    theme={selectThemeColors}
                                    options={emailClientOption}
                                    value={currentClientEmail}
                                    onChange={(data: any) => {
                                        setCurrentClientEmail(data)
                                        handleConfig('to', data.email)
                                        reset({ to: data.email })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className='my-5 d-flex align-items-center'>
                            <Col md='3'>
                                <label className='form-label mb-0'>To:</label>
                            </Col>
                            <Col md='9'>
                                <Controller name='to' control={control} rules={{ required: true }} defaultValue=''
                                    render={({ field }) =>
                                        <input
                                            type='text'
                                            className='form-control form-control-md'
                                            placeholder='Enter Email'
                                            disabled={true}
                                            value={editorHeading?.to}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                handleConfig('to', e.target.value)
                                            }}
                                        />
                                    }
                                />
                            </Col>
                            <Col md='3'>
                            </Col>
                            <Col md='9'>
                                {errors.to && (<small className="text-danger ml-2 ">To is a required field</small>)}
                            </Col>
                        </Row>
                        <Row className='my-5 d-flex align-items-center'>
                            <Col md='3'>
                                <label className='form-label mb-0'>Cc:</label>
                            </Col>
                            <Col md='9'>
                                <Controller name='cc' control={control} rules={{ required: false }} defaultValue=''
                                    render={({ field }) =>
                                        <input
                                            type='text'
                                            className='form-control form-control-md'
                                            placeholder='Enter Email'
                                            value={editorHeading?.cc}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                handleConfig('cc', e.target.value)
                                            }}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-center'>
                            <Col md='3'>
                                <label className='form-label'>Subject</label>
                            </Col>
                            <Col md='9'>
                                <input
                                    type='text'
                                    className='form-control form-control-md'
                                    placeholder='Enter Subject'
                                    value={editorHeading?.subject}
                                    onChange={(e) => handleConfig('subject', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='my-5'>
                            <Col md='3' className='pe-0'>
                                <label className='form-label'>Confirmation Email</label>
                            </Col>
                            <Col md='9'>
                                <ReactQuill
                                    theme="snow"
                                    value={editorValue}
                                    onChange={setEditorValue}
                                    modules={{
                                        toolbar: {
                                            container: [
                                                [{ header: [1, 2, 3, 4, false] }],
                                                ["bold", "italic", "underline"],
                                                [{ list: "ordered" }, { list: "bullet" }],
                                                ["link", "image"],
                                                ["blockquote"]
                                            ]
                                        },
                                    }}
                                    formats={[
                                        "header",
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strike",
                                        "list",
                                        "bullet",
                                        "link",
                                        "image"
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <Button type="button" color="secondary" className="me-3 btn-sm" onClick={() => setOpenConfirmationEmail(false)}>
                                Cancel
                            </Button>
                            <Button type='submit' color='primary' className='btn-sm' disabled={loader === 'create'}>
                                {loader === 'create' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Send</span>}
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </UILoader>
        </Modal>
    )
}

export default ConfirmationEmail