
function SystemNotification() {
  return (
    <>
        { import.meta.env.VITE_REACT_APP_ENV !== 'prod' &&
            <div className="top-notification">
                <div className="title" style={{textTransform: 'capitalize'}}>{`Fleetconx ${import.meta.env.VITE_REACT_APP_ENV} System`}</div>
            </div>
        }
    </>
  )
}

export default SystemNotification