    import React, { useEffect, useState } from 'react'
    import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap'
    import UILoader from '../../modules/commonModel/Ui-loader'
    import ReactSelect from 'react-select'
    import { Controller, useForm } from 'react-hook-form'
    import toast from 'react-hot-toast'
    import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
    import ReactQuill from 'react-quill'
    import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
    import { InvoiceService } from '../../modules/Services/Invoice'
    import { CustomerService } from '../../modules/Services/Customers'

    function IssueInvoice({ issueModal, setIssueModal, invoiceId, currentCustomer, render, setRender, setRenderElementService }) {

        const entity_id = localStorage.getItem('entity_id')
        const { control, reset, clearErrors, handleSubmit, formState: { errors } } = useForm()
        const [loader, setLoader] = useState(null)
        const [editorHeading, setEditorHeading] = useState({ to: '', cc: '', subject: '', editorValue: '' })
        const [emailClientOption, setEmailClientOption] = useState([])
        const [currentClientEmail, setCurrentClientEmail] = useState({ value: null, label: 'Select Client', email: '', name: '', is_contact: null })
        const [issueEmailType, setIssueEmailType] = useState('client')

        const issuePayment = () => {
            setLoader('issue')
            let data = {}
            if (issueEmailType === 'client') {      
                data = {
                    isInvoiceEmail: 1,
                    email: editorHeading?.to,
                    cc: editorHeading?.cc,
                    subject: editorHeading?.subject,
                    mail_body: editorHeading?.editorValue
                }
            }
            InvoiceService.issuePayment(entity_id, invoiceId, data)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setRender(!render)
                setRenderElementService(1)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => { setIssueModal(false); setLoader(null) })
        }

        const getIssueEmail = () => {
            setLoader('loading')
            InvoiceService.getIssueEmailTemplate(entity_id, { invoice_id: invoiceId })
                .then((res: any) => {
                    setEditorHeading({ to: res?.data?.response?.to, cc: '', subject: res?.data?.response?.subject, editorValue: res?.data?.response?.mail_body })
                    reset({ to: res?.data?.response?.to })
                })
                .catch((err: any) => toast.error(err?.response?.data?.message))
                .finally(() => setLoader(null));
        }

        useEffect(() => {
            if (issueModal) {
                getClientEmailList()
                getIssueEmail()
            } else {
                setEditorHeading({ to: '', cc: '', subject: '', editorValue: '' })
                setIssueEmailType('client')
                setCurrentClientEmail({ value: null, label: 'Select Client', email: '', name: '', is_contact: null })
                clearErrors()
            }
        }, [issueModal])

        const handleConfig = (field: any, value: any) => {
            setEditorHeading((pre: any) => ({ ...pre, [field]: value }))
        }

        const getClientEmailList = () => {
            const option = [];
            option.push({ value: currentCustomer?.value, label: currentCustomer?.customerEmail + ' (Client Email)', email: currentCustomer?.customerEmail, name: currentCustomer?.label, is_contact: 0 })

            CustomerService.getCustomeContactsList(entity_id, currentCustomer?.value, {})
                .then((res: any) => {
                    res.data?.response?.map((item: any) => {
                        option.push({ value: item?.id, label: item?.contact_email + ' (' + item?.contact_role + ')', email: item?.contact_email, name: item?.contact_name, is_contact: 1 })
                    })
                })
                .catch((err: any) => console.log(err?.response?.data?.message))
            setEmailClientOption(option)
        }

        return (
            <Modal isOpen={issueModal} toggle={() => setIssueModal(false)} className={`modal-dialog-centered modal-lg`}>
                <UILoader blocking={loader === 'loading'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'loading' ? 'loader-blur' : ''}>
                    <Form onSubmit={handleSubmit(issuePayment)}>
                        <ModalBody>
                            <div className='d-flex justify-content-center mb-5'>
                                <span style={{fontSize: '28px'}}>Issue Invoice</span>
                            </div>
                            <Row className='d-flex align-items-center'>
                                <Col md='4'>
                                    <div className='d-flex my-5'>
                                        <div style={{width: '40px'}} >
                                            <Input type='radio' checked={issueEmailType === 'client'} onChange={(e: any) => setIssueEmailType('client')} />
                                        </div>
                                        <p className='mb-0'>Email invoice to Client</p>
                                    </div>
                                </Col>
                                <Col md='8'>
                                    <div className='d-flex my-5'>
                                        <div style={{width: '40px'}} >
                                            <Input type='radio' checked={issueEmailType === 'manually'} onChange={(e: any) => setIssueEmailType('manually')} />
                                        </div>
                                        <p className='mb-0'>Mark as Manually Issued</p>
                                    </div>
                                </Col>
                            </Row>
                            {issueEmailType === 'client' &&
                                <>
                                    <Row className='d-flex align-items-center'>
                                        <Col md='3'>
                                            <label className='form-label mb-0'>Client Contact</label>
                                        </Col>
                                        <Col md='9'>
                                            <ReactSelect
                                                isClearable={false}
                                                className='react-select w-100'
                                                classNamePrefix='select'
                                                placeholder='Select Status'
                                                styles={customSelectStyles(null)}
                                                theme={selectThemeColors}
                                                options={emailClientOption}
                                                value={currentClientEmail}
                                                onChange={(data: any) => {
                                                    setCurrentClientEmail(data)
                                                    handleConfig('to', data.email)
                                                    reset({ to: data.email })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='my-5 d-flex align-items-center'>
                                        <Col md='3'>
                                            <label className='form-label mb-0'>To:</label>
                                        </Col>
                                        <Col md='9'>
                                            <Controller name='to' control={control} rules={{ required: true }} defaultValue=''
                                                render={({ field }) =>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-md'
                                                        placeholder='Enter Email'
                                                        disabled={true}
                                                        value={editorHeading?.to}
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                            handleConfig('to', e.target.value)
                                                        }}
                                                    />
                                                }
                                            />
                                        </Col>
                                        <Col md='3'>
                                        </Col>
                                        <Col md='9'>
                                            {errors.to && (<small className="text-danger ml-2 ">To is a required field</small>)}
                                        </Col>
                                    </Row>
                                    <Row className='my-5 d-flex align-items-center'>
                                        <Col md='3'>
                                            <label className='form-label mb-0'>Cc:</label>
                                        </Col>
                                        <Col md='9'>
                                            <input
                                                type='text'
                                                className='form-control form-control-md'
                                                placeholder='Enter Email'
                                                value={editorHeading?.cc}
                                                onChange={(e) => {
                                                    handleConfig('cc', e.target.value)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='d-flex align-items-center'>
                                        <Col md='3'>
                                            <label className='form-label'>Subject</label>
                                        </Col>
                                        <Col md='9'>
                                            <input
                                                type='text'
                                                className='form-control form-control-md'
                                                placeholder='Enter Subject'
                                                value={editorHeading?.subject}
                                                onChange={(e) => handleConfig('subject', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='my-5'>
                                        <Col md='3' className='pe-0'>
                                            <label className='form-label'>Confirmation Email</label>
                                        </Col>
                                        <Col md='9'>
                                            <ReactQuill
                                                theme="snow"
                                                value={editorHeading?.editorValue}
                                                onChange={(value) => handleConfig('editorValue', value)}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [{ header: [1, 2, 3, 4, false] }],
                                                            ["bold", "italic", "underline"],
                                                            [{ list: "ordered" }, { list: "bullet" }],
                                                            ["link", "image"],
                                                            ["blockquote"]
                                                        ]
                                                    },
                                                }}
                                                formats={[
                                                    "header",
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strike",
                                                    "list",
                                                    "bullet",
                                                    "link",
                                                    "image"
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <div>
                                <Button type="button" color="secondary" className="me-3 btn-sm" onClick={() => setIssueModal(false)}>
                                    Cancel
                                </Button>
                                <Button type='submit' color='primary' className='btn-sm' disabled={loader === 'issue'}>
                                    {loader === 'issue' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Send</span>}
                                </Button>
                            </div>
                        </ModalFooter>
                    </Form>
                </UILoader>
            </Modal>
        )
    }

    export default IssueInvoice