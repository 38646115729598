import toast from 'react-hot-toast';

export default function CopyClipboard({
    text = "",
    id = ""
}) {

    const copyToClipboard = (id: any) => {
        let copyText = document.getElementById(id);
        let text = copyText.textContent;
        navigator.clipboard.writeText(text);
        toast.success('Copied')
    };


    return (
        <div className='d-flex'>
            <p id={id}>{text}</p>
            <div onClick={() => copyToClipboard(id)} className='ms-2 cursor-pointer'>
                <i className="fa-regular fa-clone" style={{fontSize: '16px'}}></i>
            </div>
        </div>
    )
}
