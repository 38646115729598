import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button, CardText, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import toast from 'react-hot-toast';
import { JobLegsService } from '../../modules/Services/JobLegs';
import moment from 'moment';
import { DriverService } from '../../modules/Services/Drivers';
import { VehicleService } from '../../modules/Services/Vehicles';
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames';
import classnames from 'classnames';
import { LocationService } from '../../modules/Services/Location';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../modules/auth';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';
import UILoader from '../../modules/commonModel/Ui-loader';
import AsyncCreatableSelect from 'react-select/async-creatable';

function EditEventModel({ 
    openEditModel= false,
    setOpenEditModel,
    editEvent,
    statusData,
    render,
    setRender,
    edit_drag,
    allTags=[]
}) {

    const dafualtData = {
        job_number : null,
        id: null,
        driver_vehicles_id: null,
        driver: { value: '', label: 'Select Driver' },
        vehicle: { value: '', label: 'Select vehicle' },
        // pickUp: { value: '', label: 'Select PickUp' },
        // dropOff: { value: '', label: 'Select DropOff' },
        pick_up_location: null,
        drop_off_location: null,
        status: { value: '', label: 'Select Status' },
        note: '',
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        tags: [],
        job_name: null,
        cost: null,
        vehicles_required: null,
        booking_id: null,
        booking_name: ''
    }
    const { control, handleSubmit, reset, watch, setValue, getValues, formState: { errors }} = useForm()
    const entity_id = localStorage.getItem('entity_id')
    // const [currentDriver, setCurrentDriver] = useState({ value: '', label: 'Select Driver' })
    // const [currentVehicle, setCurrentVehicle] = useState({ value: '', label: 'Select Vehicle' })
    const [eventData, setEventData] = useState(dafualtData)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [driverOptions, setDriverOptions] = useState([])
    const [vehicleOptions, setVehicleOptions] = useState([])
    const [preventDriver, setPreventDriver] = useState([])
    const [preventVehicle, setPreventVehicle] = useState([])
    const [editLoader, setEditLoader] = useState(false)

    const watchedFields = watch("allocation");
    const { fields, append } = useFieldArray({
      control,
      name: "allocation"
    });

    // const [currentPickUp, setCurrentPickUp] = useState({ value: '', label: 'Select PickUp' })
    // const [currentDropOff, setCurrentDropOff] = useState({ value: '', label: 'Select DropOff' })
    // const [currentStatus, setCurrentStatus] = useState({ value: '', label: 'Select Status' })
    // const [currentNote, setCurrentNote] = useState('')
    // const [startDate, setStartDate] = useState()
    // const [endDate, setEndDate] = useState()
    // const [startTime, setStartTime] = useState(null)
    // const [endTime, setEndTime] = useState(null)
    let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let vahicleTimeoutId: ReturnType<typeof setTimeout> | null = null;
    let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const defaultDriver = {label : 'Select Driver', value : null}
    const defaultVehicle = {label : 'Select Vehicle', value : null}
    const defaultAllocationForm = {
      driver : defaultDriver,
      vehicle : defaultVehicle
    }

    const styles = {
        option : (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor : state.isDisabled ? '#e6e6e6' : '',
            color : state.isDisabled ? '#b9b9c3' : '',
            cursor : state.isDisabled ? 'not-allowed' : ''
        })
    }

    function onSubmit(data: any) {
        setLoader(true)
        const allcationData = data?.allocation?.map((item: any) => {
            return { driver_id: item?.driver?.value || null, vehicle_id: item?.vehicle?.value || null, driver_vehicles_id: item?.driver_vehicles_id || null }
        })
        const tagsIds = eventData?.tags.length > 0 ? eventData?.tags?.map((item) => item?.value) : []

        const obj = {
            booking_id: eventData?.booking_id,
            booking_name: eventData?.job_name,
            pick_up_location: data?.pick_up_location?.value,
            drop_off_location: data?.drop_off_location?.value,
            status: eventData?.status?.value,
            start_at_date: eventData?.startDate ? typeof eventData?.startDate === 'string' ? eventData?.startDate : moment(eventData?.startDate).format('YYYY-MM-DD') : null,
            start_at_time: eventData?.startTime,
            end_at_date: eventData?.endDate ? typeof eventData?.endDate === 'string' ? eventData?.endDate : moment(eventData?.endDate).format('YYYY-MM-DD') : null,
            end_at_time: eventData?.endTime,
            notes: eventData?.note,
            tags: tagsIds,
            cost: eventData?.cost,    
            vehicles_required: eventData?.vehicles_required,
            driver_vehicles_arr: allcationData
        }

        JobLegsService.updateJobLeg(entity_id, obj, editEvent?.event?._def?.extendedProps?.booking_id)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenEditModel(false)
            setRender(!render)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(false))

        // const tagsIds = eventData?.tags.length > 0 ? eventData?.tags?.map((item) => item?.value) : []
        // setLoader(true)
        // const data = {
        //     job_id: eventData?.id,
        //     new_driver: eventData?.driver?.value ? eventData?.driver?.value : null,
        //     new_vehicle: eventData?.vehicle?.value ? eventData?.vehicle?.value : null,
        //     driver_vehicles_id: eventData?.driver_vehicles_id,
        //     // pick_up: eventData?.pickUp?.value,
        //     // drop_off: eventData?.dropOff?.value,
        //     pick_up_location: formData?.pick_up_location,
        //     drop_off_location: formData?.drop_off_location,
        //     status: eventData?.status?.value,
        //     // start_at: startDate ? typeof startDate === 'string' ? startDate : moment(startDate).format('YYYY-MM-DD HH:mm') : null,
        //     // end_at: endDate ? typeof endDate === 'string' ? endDate : moment(endDate).format('YYYY-MM-DD HH:mm') : null,
        //     start_at_date: eventData?.startDate ? typeof eventData?.startDate === 'string' ? eventData?.startDate : moment(eventData?.startDate).format('YYYY-MM-DD') : null,
        //     start_at_time: eventData?.startTime,
        //     end_at_date: eventData?.endDate ? typeof eventData?.endDate === 'string' ? eventData?.endDate : moment(eventData?.endDate).format('YYYY-MM-DD') : null,
        //     end_at_time: eventData?.endTime,
        //     notes: eventData?.note,
        //     is_edit_drag: true,
        //     tags: tagsIds
        // }
        // JobLegsService.updatePlannerJob(entity_id, data)
        // .then((res: any) => {
        //   toast.success(res?.data?.message)
        //   setOpenEditModel(false)
        //   setRender(!render)
        // })
        // .catch((err: any) => {
        //   toast.error(err?.response?.data?.message)
        // })
        // .finally(() => setLoader(false))
    }
    
    function editEventData() {
        const obj1 = {
            pick_up_location: null,
            drop_off_location: null,
          }
        reset(obj1);
        JobLegsService.editJobLeg(entity_id, editEvent?.event?._def?.extendedProps?.booking_id)
            .then((res: any) => {
                let currentStatus = { value: '', label: 'Select Status' };
                statusData?.map((item: any) => {
                    if (res?.data?.response?.status?.id === item?.value) {
                        currentStatus = item;
                    }
                })

                const getTag = res?.data?.response?.tags?.map((item) => item?.id);
                const selTags = getTag?.length > 0 ? allTags?.filter((item) => getTag?.includes(item?.value)) : []

                const array = []
                res?.data?.response?.driver_vehicles_arr?.map((item: any) => {
                    array.push({ driver: { value: item?.driver?.driver_id, label: item?.driver?.driver_name || 'Select Driver' }, vehicle: { value: item?.vehicle?.vehicle_id, label: item?.vehicle?.vehicle_name || 'Select Vehicle' }, driver_vehicles_id: item?.driver_vehicles_id })
                })

                const vehicle_req = res?.data?.response?.vehicles_required;
                let totalArr: any = []
                if (vehicle_req - array?.length > 0) {
                    let Arr: any = []
                    for(let i=1; i <= vehicle_req - array?.length; i++) {
                        Arr.push(defaultAllocationForm)
                    }
                    totalArr = [...array, ...Arr];
                } else if(array?.length - vehicle_req > 0) {
                    totalArr = array.splice(array?.length - vehicle_req);
                } else {
                    totalArr = array;
                }

                setEventData({
                    ...res?.data?.response,
                    pick_up_location: res?.data?.response?.pick_up_location,
                    drop_off_location: res?.data?.response?.drop_off_location,
                    startDate: res?.data?.response?.start_at_date,
                    startTime: res?.data?.response?.start_at_time,
                    endDate: res?.data?.response?.end_at_date,
                    endTime: res?.data?.response?.end_at_time,
                    note: res?.data?.response?.notes || '',
                    status: currentStatus,
                    tags: selTags,
                })

                const obj = {
                    pick_up_location: { value: res?.data?.response?.pick_up_location, label: res?.data?.response?.pick_up_location},
                    drop_off_location: { value: res?.data?.response?.drop_off_location, label: res?.data?.response?.drop_off_location},
                    allocation: totalArr
                }
                reset(obj);

                const selAllocationDriver = []
                const selAllocationVehicle = []
                const arr = res?.data?.response?.driver_vehicles_arr
                const allocationFormResArr = arr?.map((item: any, i: any) => {
                    selAllocationDriver.push({label : item?.driver?.driver_name, value : item?.driver?.driver_id})
                    selAllocationVehicle.push({label : item?.vehicle?.vehicle_name, value : item?.vehicle?.vehicle_id})
                    return {
                        driver : item?.driver ? {label : item?.driver?.driver_name, value : item?.driver?.driver_id } : defaultDriver,
                        vehicle : item?.vehicle ? {label : item?.vehicle?.vehicle_name, value: item?.vehicle?.vehicle_id} : defaultVehicle,
                        driver_vehicles_id: item?.driver_vehicles_id
                    }
                })

                setPreventDriver(selAllocationDriver)
                setPreventVehicle(selAllocationVehicle)
                // append(allocationFormResArr?.length ? allocationFormResArr : [defaultAllocationForm])
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message);
            })
            .finally(() => setEditLoader(false))

        // JobLegsService.editIndividualJob(entity_id, editEvent?.event?._def?.extendedProps?.driver_vehicles_id)
        // .then((res: any) => {
        //     setEventData(res?.data?.response)

        //     let currentStatus = { value: '', label: 'Select Status' };
        //     statusData?.map((item: any) => {
        //         if (res?.data?.response?.status === item?.value) {
        //             currentStatus = item;
        //         }
        //     })
        //     const getTag = JSON.parse(res?.data?.response?.tags)
        //     const selTags = getTag?.length > 0 ? allTags.filter((item) => getTag?.includes(item?.value)) : []
        //     setEventData({
        //         ...res?.data?.response,
        //         driver: { value: res?.data?.response?.driver_id || '', label: res?.data?.response?.driver_name },
        //         vehicle: { value: res?.data?.response?.vehicle_id || '', label: res?.data?.response?.vehicle_name },
        //         // pickUp: { value: res?.data?.response?.pick_up, label: res?.data?.response?.pickup },
        //         // dropOff: { value: res?.data?.response?.drop_off, label: res?.data?.response?.dropoff },
        //         pick_up_location: res?.data?.response?.pick_up_location,
        //         drop_off_location: res?.data?.response?.drop_off_location,
        //         startDate: res?.data?.response?.start_at_date,
        //         startTime: res?.data?.response?.start_at_time,
        //         endDate: res?.data?.response?.end_at_date,
        //         endTime: res?.data?.response?.end_at_time,
        //         note: res?.data?.response?.notes || '',
        //         status: currentStatus,
        //         tags: selTags
        //     })

        //     const obj = {
        //         pick_up_location: res?.data?.response?.pick_up_location,
        //         drop_off_location: res?.data?.response?.drop_off_location,
        //       }
        //     reset(obj);
        // })
        // .catch((err: any) => {
        //     toast.error(err?.response?.data?.message);
        // });
    }

    function manipulateAllocation(value: any) {
        if (value - fields?.length > 0) {
            const Arr: any = []
            for(let i=1; i <= value - fields?.length; i++) {
                Arr.push(defaultAllocationForm)
            }
            setValue('allocation', [...fields, ...Arr])
        } else if (value - fields?.length === 0) {
        } else if (value === 0) {
            setValue('allocation', [])
        } else {
            const Arr = fields
            Arr.splice(value - fields?.length);
            setValue('allocation', Arr)
        }
    }

    const handleSelectedDriverAndVehicle = (field: any) => {
        if (field === 'driver') {
            const selQuoteStatusArr = watchedFields?.map((item: any) => item?.driver)
            setPreventDriver(selQuoteStatusArr)
        } else {
            const selQuoteStatusArr = watchedFields?.map((item: any) => item?.vehicle)
            setPreventVehicle(selQuoteStatusArr)
        }
    }

    const loadLocationOptions = (inputValue: any, callback: any) => {
        if (locationTimeoutId) {
            clearTimeout(locationTimeoutId);
        }
        locationTimeoutId = setTimeout(() => {
            const obj = { 
                name : inputValue
            }
            LocationService.getSuggestLocationList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.location_name, label: item?.location_name }
                }))
            })
            .catch((err: any) => console.log(err?.response?.data?.message))
        }, 1000)
    }

    useEffect(() => {
        if (openEditModel) {
            setEditLoader(true)
            getDriverList()
            getVehicleList()  
            append(defaultAllocationForm)
            setTimeout(() => {
                editEventData()
            }, 1000);
        } else {
            setEventData(dafualtData)
            reset({ allocation: [] });
        }
    }, [openEditModel])

    const loadDriverOptions = (inputValue: any, callback: any) => {
        if (driverTimeoutId) {
            clearTimeout(driverTimeoutId);
        }
        driverTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,driver_name',
                driver_name : inputValue
            }
            DriverService.getDriverList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.driver_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000);
    }

    const loadVahicleOptions = (inputValue: any, callback: any) => {
        if (vahicleTimeoutId) {
            clearTimeout(vahicleTimeoutId);
        }
        vahicleTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,vehicle_name',
                vehicle_name : inputValue
            }
            VehicleService.getVehicleList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.vehicle_name}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const getDriverList = () => {
        const obj = { 
            getall: 1,
            fields: 'id,driver_name'
        }
        DriverService.getDriverList(entity_id, obj)
        .then((res: any) => {
            const data = res?.data?.response?.map((item: any) => {
                return { value: item?.id, label: item?.driver_name}
            })
            const defaultDriver = {value: '', label: 'Select Driver'};
            setDriverOptions([defaultDriver, ...data])
        })
        .catch(err => console.log(err?.response?.data?.message))
    }
  
    const getVehicleList = () => {
        const obj = { 
            getall: 1,
            fields: 'id,vehicle_name'
        }
        VehicleService.getVehicleList(entity_id, obj)
        .then((res: any) => {
            const data = res?.data?.response?.map((item: any) => {
                return { value: item?.id, label: item?.vehicle_name}
            })
            const defaultDriver = {value: '', label: 'Select Vehicle'};
            setVehicleOptions([defaultDriver, ...data])
        })
        .catch(err => console.log(err?.response?.data?.message))
    }

  return (
    <>
        <Modal className="modal-dialog-centered modal-lg" isOpen={openEditModel} toggle={() => setOpenEditModel(false)}>
            <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
                <ModalBody className="p-10">
                    <div className="pb-10 d-flex justify-content-center align-items-center" >
                        <h1 className='mb-0'>{eventData?.job_number + ' ' + (eventData?.job_name ? ('(' + eventData?.job_name + ')') : '')}</h1>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="pt-5">
                            <Col md={6} xs={12} className='mb-5'>
                                <Label className='form-label' for='pick_up_location'>Pick Up Location</Label>
                                <Controller name='pick_up_location' control={control} defaultValue='' rules={{required : false}}
                                    render={({ field }) => ( 
                                        <AsyncCreatableSelect
                                            {...field}
                                            className='react-select'
                                            classNamePrefix='select'
                                            isClearable={false}
                                            isSearchable={true}
                                            styles={customSelectStyles(null)}
                                            loadOptions={loadLocationOptions}
                                            theme={selectThemeColors}
                                            onChange={(data: any) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    )} 
                                />
                            </Col>
                            <Col md={6} xs={12} className='mb-5'>
                                <Label className='form-label' for='drop_off_location'>Drop Off Location</Label>
                                <Controller name='drop_off_location' control={control} defaultValue='' rules={{required : false}}
                                    render={({ field }) => ( 
                                        <AsyncCreatableSelect
                                            {...field}
                                            className='react-select'
                                            classNamePrefix='select'
                                            isClearable={false}
                                            isSearchable={true}
                                            styles={customSelectStyles(null)}
                                            loadOptions={loadLocationOptions}
                                            theme={selectThemeColors}
                                            onChange={(data: any) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    )} 
                                />
                                {errors.drop_off_location && (<small className="text-danger ml-2">Drop off location is a required field</small>)}
                            </Col>
                        </Row>
                        <Row className="my-10">
                            <Col md={4} xs={12} className="mb-1">
                                <Label className="form-label">Start</Label>
                                <div className='d-flex'>
                                    <Col md='7'>    
                                        <Flatpickr
                                            className='form-control' 
                                            placeholder='Select Start Date'
                                            value={eventData?.startDate}
                                            options={{ 
                                                enableTime: false, 
                                                altInput: true, 
                                                dateFormat: 'Y-m-d H:i',
                                                disableMobile : true,
                                                altInputClass: classNames(
                                                'form-control flatpickr-input'
                                                ) 
                                            }} 
                                            onChange={(value: any) => {
                                                setEventData({
                                                    ...eventData,
                                                    startDate: value[0]
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col md='5'>
                                        <Input type='time' className='cursor-pointer' value={eventData?.startTime} 
                                            onChange={(e) => 
                                                setEventData({
                                                    ...eventData,
                                                    startTime: e.target.value
                                                })
                                            } 
                                        />
                                    </Col>
                                </div>
                            </Col>
                            <Col md={4} xs={12} className="mb-1">
                                <Label className="form-label">End</Label>
                                <div className='d-flex'>
                                    <Col md='7'>    
                                        <Flatpickr 
                                            className='form-control' 
                                            placeholder='Select End Date'
                                            value={eventData?.endDate}
                                            options={{ 
                                                enableTime: false, 
                                                altInput: true, 
                                                dateFormat: 'Y-m-d H:i',
                                                disableMobile : true,
                                                minDate: eventData?.startDate,
                                                altInputClass: classnames(
                                                'form-control flatpickr-input'
                                                ) 
                                            }} 
                                            onChange={(value: any) => {
                                                setEventData({
                                                    ...eventData,
                                                    endDate: value[0]
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col md='5'>
                                        <Input type='time' className='cursor-pointer' value={eventData?.endTime} 
                                            onChange={(e) => 
                                                setEventData({
                                                    ...eventData,
                                                    endTime: e.target.value
                                                })
                                            } 
                                        />
                                    </Col>
                                </div>
                            </Col>
                            <Col md={4} xs={12} className="mb-1">
                                <Label className="form-label">Status</Label>
                                <ReactSelect
                                    isClearable={false}
                                    className='react-select'
                                    classNamePrefix='select'
                                    isDisabled={true}
                                    theme={selectThemeColors}
                                    options={statusData}
                                    value={eventData?.status}
                                    onChange={(data: any) => {
                                        setEventData({
                                            ...eventData,
                                            status: data
                                        })
                                    }}
                                />         
                            </Col>
                        </Row>
                        <Row className='my-10'>
                            <h6 className="form-label">Driver Notes</h6>
                            <Input type='textarea' className='mx-2' rows='2' value={eventData?.note} 
                                onChange={(e) => 
                                    setEventData({
                                        ...eventData,
                                        note: e.target.value
                                    })
                                } 
                            />
                        </Row>
                        <Row className='my-10'>
                            <h6 className="form-label">Tags</h6>
                            <ReactSelect 
                                isMulti 
                                id='planner-tags'
                                className='react-select'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                options={allTags}
                                theme={selectThemeColors}
                                value={eventData?.tags}
                                onChange={(data:any) => {
                                    setEventData({
                                        ...eventData,
                                        tags: data
                                    })
                                }}
                            />
                        </Row>
                        <Row className='my-10'>
                            <Col md={6}>
                                <h6 className="form-label">Vehicles Required</h6>
                                <Input type='number' 
                                    value={eventData?.vehicles_required}
                                    onChange={(e: any) => {
                                        setEventData({
                                            ...eventData,
                                            vehicles_required: e.target.value
                                        })
                                        manipulateAllocation(Number(e.target.value))
                                    }} 
                                />
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col md={6} className="qs-head">
                                <CardText>Driver</CardText>
                            </Col>
                            <Col md={6} className="qs-head">
                                <CardText>Vehicle</CardText>
                            </Col>
                        </Row>
                        <div style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
                            { fields?.length > 0 ? fields?.map((item, index) => (
                                <Row key={item?.id} className="mb-3">
                                    <Col md={6}>
                                        <Controller name={`allocation.${index}.driver`} control={control} defaultValue={defaultDriver}
                                            render={({ field }) => (
                                            currentUser?.dropdown_counts?.drivers_count < 1000 ?
                                                <ReactSelect
                                                    {...field}
                                                    isClearable={false}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    theme={selectThemeColors}
                                                    styles={styles}
                                                    options={driverOptions}
                                                    isOptionDisabled = {(option) => {
                                                        return preventDriver?.some((elem) => elem?.value === option?.value)
                                                    }}
                                                    onChange={(data: any) => {
                                                        field.onChange(data)
                                                        handleSelectedDriverAndVehicle('driver')
                                                    }}
                                                />
                                                :
                                                <AsyncSelect
                                                    {...field} 
                                                    isClearable={true}
                                                    className='react-select w-100'
                                                    classNamePrefix='select'
                                                    placeholder='Search Driver'
                                                    isRtl={false}
                                                    name='callback-react-select'
                                                    theme={selectThemeColors}
                                                    loadOptions={loadDriverOptions}
                                                    cacheOptions
                                                    styles={styles}
                                                    isOptionDisabled = {(option) => {
                                                        return preventDriver?.some((elem) => elem?.value === option?.value)
                                                    }}
                                                    onChange={(data: any) => {
                                                        field.onChange(data)
                                                        handleSelectedDriverAndVehicle('driver')
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col> 
                                    <Col md={6}>
                                        <Controller name={`allocation.${index}.vehicle`} control={control} defaultValue={defaultVehicle}
                                            render={({ field }) => (
                                            currentUser?.dropdown_counts?.vehicles_count < 1000 ?
                                                <ReactSelect
                                                    {...field}
                                                    isClearable={false}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    theme={selectThemeColors}
                                                    styles={styles}
                                                    options={vehicleOptions}
                                                    isOptionDisabled = {(option) => {
                                                        return preventVehicle?.some((elem) => elem?.value === option?.value)
                                                    }}
                                                    onChange={(data: any) => {
                                                        field.onChange(data)
                                                        handleSelectedDriverAndVehicle('vehicle')
                                                    }}
                                                />
                                                :
                                                <AsyncSelect
                                                    {...field} 
                                                    isClearable={true}
                                                    className='react-select w-100'
                                                    classNamePrefix='select'
                                                    placeholder='Search Vehicle'
                                                    isRtl={false}
                                                    name='callback-react-select'
                                                    theme={selectThemeColors}
                                                    loadOptions={loadVahicleOptions}
                                                    cacheOptions
                                                    isOptionDisabled = {(option) => {
                                                        return preventVehicle?.some((elem) => elem?.value === option?.value)
                                                    }}
                                                    styles={styles}
                                                    onChange={(data: any) => {
                                                        field.onChange(data)
                                                        handleSelectedDriverAndVehicle('vehicle')
                                                    }}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Row>
                                )) : (
                                    <Row>
                                        <Col xs={12} lg={12}>
                                            no Record found
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                            {/* <Col md={6} xs={12} className="mb-1">
                                <Label className="form-label">Driver</Label>
                                {currentUser?.dropdown_counts?.drivers_count < 1000 ?
                                    <ReactSelect
                                        isClearable={false}
                                        className='react-select'
                                        classNamePrefix='select'
                                        theme={selectThemeColors}
                                        styles={customSelectStyles(null)}
                                        options={driverOptions}
                                        value={eventData?.driver}
                                        onChange={(data: any) => {
                                            setEventData({
                                                ...eventData,
                                                driver: data
                                            })
                                        }}
                                    />
                                    :
                                    <AsyncSelect
                                        isClearable={true}
                                        className='react-select w-100'
                                        classNamePrefix='select'
                                        placeholder='Search Driver'
                                        isRtl={false}
                                        name='callback-react-select'
                                        loadOptions={loadDriverOptions}
                                        styles={customSelectStyles(null)}
                                        value={eventData?.driver}
                                        cacheOptions
                                        onChange={(data: any) => {
                                            // setCurrentDriver(data)
                                            setEventData({
                                                ...eventData,
                                                driver: data
                                            })
                                        }}
                                    />
                                }
                            </Col>
                            <Col md={6} xs={12} className="mb-1">
                                <Label className="form-label">Vehicle</Label>
                                {currentUser?.dropdown_counts?.vehicles_count < 1000 ?
                                    <ReactSelect
                                        isClearable={false}
                                        className='react-select'
                                        classNamePrefix='select'
                                        theme={selectThemeColors}
                                        styles={customSelectStyles(null)}
                                        options={vehicleOptions}
                                        value={eventData?.vehicle}
                                        onChange={(data: any) => {
                                            setEventData({
                                                ...eventData,
                                                vehicle: data
                                            })
                                        }}
                                    />
                                    :
                                    <AsyncSelect
                                        isClearable={true}
                                        className='react-select w-100'
                                        classNamePrefix='select'
                                        placeholder='Search Vehicle'
                                        isRtl={false}
                                        name='callback-react-select'
                                        loadOptions={loadVahicleOptions}
                                        styles={customSelectStyles(null)}
                                        value={eventData?.vehicle}
                                        cacheOptions
                                        onChange={(data: any) => {
                                            // setCurrentVehicle(data)
                                            setEventData({
                                                ...eventData,
                                                vehicle: data
                                            })
                                        }}
                                    />
                                }
                            </Col> */}
                        <Row>
                            <Col xs={12} className="mt-10">
                                <div className='d-flex justify-content-end'>
                                    <Button type='button' color="primary" onClick={() => navigate(`/jobs/${eventData?.id}/edit`)}>
                                        View Job
                                    </Button>
                                    <Button type="button" className="btn btn-secondary mx-3" onClick={() => setOpenEditModel(false)}>
                                        Close
                                    </Button>
                                    <Button color="primary" type="submit" disabled={loader}>
                                        { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </UILoader>
        </Modal>
    </>
  )
}

export default EditEventModel