import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import ReactSelect from 'react-select'
import { JobService } from '../../modules/Services/Job'
import { CustomerService } from '../../modules/Services/Customers'
import { customSelectStyles, preventDefualt, selectThemeColors } from '../../modules/commonModel/Utils'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import moment from 'moment'
import InvoiceModel from '../Invoices/InvoiceModel'
import AsyncSelect from 'react-select/async'
import { TagsService } from '../../modules/Services/Tags'
import { MoreVertical, Plus } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { JobLegsService } from '../../modules/Services/JobLegs'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { LocationService } from '../../modules/Services/Location'
import { AddCustomer } from '../Customers/AddCustomer'
import { KTIcon } from '../../../_metronic/helpers'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AddRecurringJob from './AddRecurringJob'
import { IconComponent } from '../../../_metronic/helpers/components/IconComponent'
import CopyBookingModal from './CopyBookingModal'
import ConfirmationEmail from './ConfirmationEmail'
import { useAuth } from '../../modules/auth'
import HistoryModal from '../../modules/commonModel/HistoryModal'
import ReactQuill from 'react-quill'
import BookingDeposit from './BookingDeposit'

const AddJob = () => {
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm()
  const { currentUser } = useAuth()
  const {id} = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(null)
  const [currentCustomer, setCurrentCustomer] = useState({ value: '', label: 'Select Client', email: ''})
  const [statusData, setStatusData] = useState([])
  const [currentStatus, setCurrentStatus] = useState({ value: null, label: 'Select Status'})
  const [jobLegByJob, setJobLegByJob] = useState([])
  const [priceType, setPriceType] = useState({value: 0, label: 'Price by Booking'})
  const [jobPrice, setJobPrice] = useState('')
  const [bookingData, setBookingData] = useState({ id: null, customer_id: null, customer_name: null, company_name: null, booking_number: null, job_name: null, job_price: null, job_leg_sum: null, job_status: null, tag_jobs: null, payment_status: null})
  const [openInvoice, setOpenInvoice] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const entity_id = localStorage.getItem('entity_id')
  // const [render, setRender] = useState(false)
  // const [flag, setFlag] = useState(false)
  const [tableHeight, setTableHeight] = useState(0)
  const [openDeleteJobModal, setOpenDeleteJobModal] = useState(false)
  const [deleteJobId, setDeleteJobId] = useState(null)
  const [editLoader, setEditLoader] = useState(false)
  const [renderElementService, setRenderElementService] = useState(1)
  // const [check, setCheck] = useState({ timeout: null })
  const [purchaseOrder, setPurchaseOrder] = useState('')
  const [notes, setNotes] = useState('')
  const [internal_notes, setInternal_notes] = useState('')

  const watchQs = useWatch({ name: "jobs", control })
  const [customerSearch, setCustomerSearch] = useState('')
  const [openCustomerModel, setOpenCustomerModel] = useState(false)
  const [customerName, setCustomerName] = useState({ value: null, label: 'select', email: '' })
  const [searchBy, setSearchBy] = useState('customer_name')
  const [createJobLoader, setCreateJobLoader] = useState(false)

  const [openRecurringJobModal, setOpenRecurringJobModal] = useState(false)
  const [recurringJobId, setRecurringJobId] = useState(null)

  const [searchValue, setSearchValue] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const asyncSelectRef = useRef(null);
  const selectWrapperRef = useRef(null);
  const searchRef = useRef('');
  const [jobTypesList, setJobTypesList] = useState([])
  const [currentJobType, setCurrentJobType] = useState({ value: 1, label: 'Normal' })

  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [recurring_job_settings, setRecurring_job_settings] = useState(null)
  const [openConfirmationEmail, setOpenConfirmationEmail] = useState(false)
  const [bookingCancelModal, setBookingCancelModal] = useState(false)

  const [openHistoryModal, setOpenHistoryModal] = useState(false)

  let locationTimeoutId: ReturnType<typeof setTimeout> | null = null;
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [currentBillTo, setCurrentBillTo] = useState({ value: '', label: 'Select Client'})
  let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const [historyData, setHistoryData] = useState([])
  const [tagData, setTagData] = useState([])
  const [emailPreview, setEmailPreview] = useState({ isOpen: false,  value: null })
  const [depositModal, setDepositModal] = useState({ isOpen: false, booking_id: null, customer_id: null })
  const [currentTag, setCurrentTag] = useState<TagOption[]>([])
  type TagOption = {
    label: string;
    value: string;
  };

  const priceTypeData = [
    { value: 0, label: 'Price by Booking'},
    { value: 1, label: 'Price by Jobs(s)'}
  ]

  const defaultJobLeg = {
    status: {id: 1, label: "New"},
    pick_up_location: null,
    drop_off_location: null,
    start_at_date: moment(new Date()).format('YYYY-MM-DD'),
    start_at_time: moment(new Date()).minutes(0).seconds(0).format('HH:mm'),
    end_at_date: moment(new Date()).format('YYYY-MM-DD'),
    end_at_time: moment(new Date()).minutes(0).seconds(0).format('HH:mm'),
    pax: null,
    vehicles_required: 1,
    cost: 0,
    ref: null,
    notes: null
  }

  async function fetchFormData() {
    setLoader('create')

    // const jobsData = data?.jobs?.map((item: any) => {
    // const jobsData = watchQs?.map((item: any) => {
    //   return {...item, pick_up: item?.pick_up?.value, drop_off: item?.drop_off?.value}
    // })
    const data = getValues()

    const jobsData = watchQs?.map((item: any) => {
      if (item?.status) item.status = item?.status?.id ? item?.status?.id : null;
      return {...item, pick_up_location: item?.pick_up_location?.value || '', drop_off_location: item?.drop_off_location?.value || ''}
    })

    const obj = {
      entity_id: entity_id,
      job_name: data?.job_name,
      search_by_client: searchBy === 'customer_name' ? 0 : searchBy === 'company_name' ? 1 : 0,
      customer_id: currentCustomer?.value,
      bill_to_customer_id: currentBillTo?.value,
      price_by_job: priceType?.value === 0 ? 1 : 0,
      price_by_job_legs: priceType?.value === 1 ? 1 : 0,
      job_price: jobPrice,
      booked_by: data?.booked_by,
      job_status: currentStatus?.value,
      tag_jobs: currentTag?.map((item) => { return item?.value }),
      coachiejoblegs: jobsData,
      purchase_order:purchaseOrder,
      booking_notes: notes,
      internal_notes: internal_notes,
      recurring_job_settings: recurring_job_settings,
      job_types: currentJobType?.value
    }
    if (id) {
      delete obj.entity_id
      delete obj.recurring_job_settings
    }

    return JobService[id ? 'updateJob' : 'storeJob'](entity_id, obj, id)
      .then((res: any) => {
        const data = res?.data?.response
        toast.success(res?.data?.message)
        setBookingData(data)
        if (!id) navigate(`/booking/${data?.id}/edit`)
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(null))
  }

  function append(data: any) {
    const arr = data?.map((item: any) => {
      const obj = {
        id: item?.id,
        pax: item?.pax,
        vehicles_required: item?.vehicles_required,
        // pick_up: {value: item?.pick_up, label: item?.pick_up_location},
        // drop_off: {value: item?.drop_off, label: item?.drop_off_location},
        pick_up_location: {value: item?.pick_up_location, label: item?.pick_up_location},
        drop_off_location: {value: item?.drop_off_location, label: item?.drop_off_location},
        // start_at: item?.start_at,
        // end_at: item?.end_at,
        start_at_date: item?.start_at_date,
        start_at_time: item?.start_at_time,
        end_at_date: item?.end_at_date,
        end_at_time: item?.end_at_time,
        cost: item?.cost,
        job_reference: item?.job_reference,
        notes: item?.notes
      }
      return obj
    })
    return arr
  }

  function editData() {
    setEditLoader(true)
    JobService.editJob(entity_id, id)
      .then((res: any) => {
        setJobLegByJob(res?.data?.response?.coachiejoblegs)
        setSearchBy(res?.data?.response?.search_by_client === 1 ? 'company_name' : res?.data?.response?.search_by_client === 0 ? 'customer_name' : 'customer_name')
        setPriceType(res?.data?.response?.price_by_job === 1 ? {value: 0, label: 'Price by Booking'} : res?.data?.response?.price_by_job_legs === 1 ? {value: 1, label: 'Price by Jobs(s)'} : {value: 0, label: 'Price by Booking'})
        setBookingData(res?.data?.response)
        setCurrentCustomer({ value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name, email: res?.data?.response?.customer?.customer_email })
        setJobPrice(res?.data?.response?.price_by_job === 1 ? res?.data?.response?.job_price : res?.data?.response?.price_by_job_legs === 1 ? res?.data?.response?.job_leg_sum : null)
        setPurchaseOrder(res?.data?.response?.purchase_order)
        setNotes(res?.data?.response?.booking_notes || '')
        setInternal_notes(res?.data?.response?.internal_notes || '')
        setRecurring_job_settings(res?.data?.response?.recurring_job_settings)
        setCurrentBillTo({ value: res?.data?.response?.bill_to_customer_id, label: res?.data?.response?.bill_to_customer_name })
        const sumWithInitial = res?.data?.response?.booking_deposit?.reduce((accumulator, currentValue) => accumulator + currentValue?.amount, 0)

        const obj = {
          job_name: res?.data?.response?.job_name,
          bill_to: { value: res?.data?.response?.bill_to_customer_id, label: res?.data?.response?.bill_to_customer_name },
          booking_number: res?.data?.response?.booking_number,
          customer: { value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name },
          price: res?.data?.response?.price_by_job === 1 ? res?.data?.response?.job_price : res?.data?.response?.price_by_job_legs === 1 ? res?.data?.response?.job_leg_sum : null,
          jobs: append(res?.data?.response?.coachiejoblegs),
          booked_by: res?.data?.response?.booked_by,
          booking_deposit: sumWithInitial
        }
        reset(obj)
      })
      .catch((err: any) => {
        console.log('err 216', err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (bookingData && statusData?.length > 0 && tagData?.length > 0) {
      const obj = getValues()    
      statusData?.map((item) => {
        if (bookingData?.job_status?.id === item?.value) {
          obj.status = item
          setCurrentStatus(item)
        }
      })
      setCurrentTag([])
      tagData?.filter(item => {
        bookingData?.tag_jobs?.filter((i: any) => {
          if (item.value === i?.id) {
            setCurrentTag((p: any) => [...p, item])
          }
        })
      })
      reset(obj)
    }
  }, [bookingData, statusData, tagData])

  function getJobStatusList() {
    JobService.getJobStatusList(entity_id)
    .then((res: any) => {
      const customerList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      const dafault = { value: '', label: 'Select Status'}
      setStatusData([dafault, ...customerList])
    })
    .catch((err: any) => {
      console.log('err 253', err?.response?.data?.message)
    })
  }

  function getJobStatusData() {
    JobService.getJobStatusData(entity_id)
    .then((res: any) => {
      const data = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setJobTypesList(data)
    })
    .catch((err: any) => {
      console.log('err 266', err?.response?.data?.message)
    })
  }

  function getTagList() {
    const data = {
      getall: 1,
      fields: 'id,name'
    }
    TagsService.getTagsList(entity_id, data)
    .then((res: any) => {
      const tagsList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setTagData(tagsList)
    })
    .catch((err: any) => {
      console.log('err 157', err?.response?.data?.message)
    })
  }

  useEffect(() => {
    getJobStatusList()
    getJobStatusData()
    getTagList()
  }, [])
  
  useEffect(() => {
    if (renderElementService && id) {
      setRenderElementService(0)
      editData()
    }
  }, [id, renderElementService])

  useEffect(() => {
    if (!id && statusData?.length) {
      const obj = getValues()
      obj.status = { value: 2, label: 'New' }
      setCurrentStatus({ value: 2, label: 'New' })
      reset(obj)
    }
  }, [statusData])

  function forceDeleteBooking() {
    setPending(true)
    JobService.deleteJob(entity_id, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate('/booking')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  function changeDate(key: any, value: any, item: any) {
    if ((key === 'start_at' || key === 'end_at' ) && item[key] === moment(value[0]).format('YYYY-MM-DD HH:mm:ss')) return

    setEditLoader(true)
    const data = {
      start_at: key === 'start_at' ? value[0] ? moment(value[0]).format('YYYY-MM-DD HH:mm') : null : item?.start_at,
      end_at: key === 'end_at' ?  value[0] ? moment(value[0]).format('YYYY-MM-DD HH:mm') : null : item?.end_at,
      vehicles_required: key === 'vehicles_required' ?  value ? value : null : item?.vehicles_required,
      pax : key === 'pax' ?  value ? value : null : item?.pax,
      // pick_up: key === 'pick_up' ?  value ? value?.value : null : item?.pick_up,
      // drop_off: key === 'drop_off' ?  value ? value?.value : null : item?.drop_off,
      pick_up_location: key === 'pick_up_location' ?  value ? value.value : null : item?.pick_up_location,
      drop_off_location: key === 'drop_off_location' ?  value ? value.value : null : item?.drop_off_location,
      cost: item?.cost
    }
    JobLegsService.updateJobLeg(entity_id, data, item?.id)
    .then((res: any) => {
      toast.success(res?.data?.message)
      setRenderElementService(1)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
  }

  function createCloneJob(item: any, field: any) {
    setEditLoader(true)

    fetchFormData()
    .then(() => {
      const data = {
        return_leg: field === 'return_job' ? 1 : null,
      };

      return JobLegsService.cloneJobLeg(entity_id, item?.id, data);
    })
    .then((res: any) => {
      toast.success(res?.data?.message);
      setRenderElementService(1);
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message);
    })
    .finally(() => setEditLoader(false));

    // const data = {
    //   return_leg: field === 'return_job' ? 1 : null
    // }

    // JobLegsService.cloneJobLeg(entity_id, item?.id, data)
    // .then((res: any) => {
    //   toast.success(res?.data?.message)
    //   setRenderElementService(1)
    // })
    // .catch((err: any) => {
    //   toast.error(err?.response?.data?.message)
    // })
  }

  function deleteJob() {
    setEditLoader(true)
    setPending(true)

    fetchFormData()
    .then(() => {
      return JobLegsService.deleteJobLeg(entity_id, deleteJobId);
    })
    .then((res: any) => {
      // toast.success(res?.data?.message)
      setOpenDeleteJobModal(false)
      setDeleteJobId(null)
      setRenderElementService(1)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message);
    })
    .finally(() => setPending(false));
  
    // JobLegsService.deleteJobLeg(entity_id, deleteJobId)
    // .then((res: any) => {
    //   toast.success(res?.data?.message)
    //   setOpenDeleteJobModal(false)
    //   setDeleteJobId(null)
    //   setRenderElementService(1)
    // })
    // .catch((err: any) => {
    //   toast.error(err?.response?.data?.message)
    // })
    // .finally(() => setPending(false))
  }

  useEffect(() => {
    if (window.innerHeight < 700){
      setTableHeight(200)
    } else if (window.innerHeight < 800){
      setTableHeight(300)
    } else if (window.innerHeight < 900){
      setTableHeight(400)
    } else if (window.innerHeight < 1000){
      setTableHeight(500)
    } else if (window.innerHeight < 1100){
      setTableHeight(600)
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 700){
        setTableHeight(200)
      } else if (window.innerHeight < 800){
        setTableHeight(300)
      } else if (window.innerHeight < 900){
        setTableHeight(400)
      } else if (window.innerHeight < 1000){
        setTableHeight(500)
      } else if (window.innerHeight < 1100){
        setTableHeight(600)
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function deleteJobConfirmation(id: any) {
    setDeleteJobId(id)
    setOpenDeleteJobModal(true)
  }

  function createRecurring(id: any) {
    setRecurringJobId(id)
    setOpenRecurringJobModal(true)
  }

  const loadLocationOptions = (inputValue: any, callback: any) => {
    if (locationTimeoutId) {
      clearTimeout(locationTimeoutId);
    }
    // if (inputValue?.length > 3) {
      locationTimeoutId = setTimeout(() => {
        const obj = { 
          name : inputValue
        }
        LocationService.getSuggestLocationList(entity_id, obj)
        .then((res: any) => {
          callback(res?.data?.response?.map((item: any) => {
            return { value: item?.location_name, label: item?.location_name }
          }))
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
      }, 1000)
    // }
  }

  useEffect(() => {
    if (customerName?.value) {
      setValue('customer', customerName )
      setCurrentCustomer(customerName)
    }
  }, [customerName])

  // useEffect(() => {
  //   if (searchBy === 'customer_name') {
  //     setValue('customer', { value: bookingData?.customer_id, label: bookingData?.customer_name })
  //     setCurrentCustomer({ value: bookingData?.customer_id, label: bookingData?.customer_name })
  //   } else if(searchBy === 'company_name') {
  //     setValue('customer', { value: bookingData?.customer_id, label: bookingData?.company_name })
  //     setCurrentCustomer({ value: bookingData?.customer_id, label: bookingData?.company_name })
  //   }
  // }, [searchBy])

  function RadioButton ({ name, value, checked, setChecked, label }) {
    return (
      <>
        <Input type='radio' name={name} className='me-5 ml-5' value={value} checked={checked} onChange={(e) => setChecked(e.target.value)} />
        <Label className='form-label text-muted sm' for={name}>{label}</Label>
      </>
    )
  }

  function createNewJob() {
    setCreateJobLoader(true)
    const obj = {
      entity_id: entity_id,
      booking_id: id,
      start_at_date: moment(new Date()).format('YYYY-MM-DD'),
      start_at_time: moment(new Date()).minutes(0).seconds(0).format('HH:mm'),
      end_at_date: moment(new Date()).format('YYYY-MM-DD'),
      end_at_time: moment(new Date()).minutes(0).seconds(0).format('HH:mm'),
      cost: 0,
      status: 1,
      vehicles_required: 1,
      job_reference: '',
      notes: ''
    }
    JobLegsService.storeJobLeg(entity_id, obj)
    .then((res: any) => {
      toast.success(res?.data?.message)
      setRenderElementService(1)
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.message)
    })
    .finally(() => setCreateJobLoader(false))
  }

  const appendJobLeg = () => {
    setJobLegByJob(pre => [...pre, defaultJobLeg])
    setValue('jobs', [...(getValues('jobs')) || [], defaultJobLeg])

    setTimeout(() => {
      const selectElement = document.getElementById(`pick-up-locations${jobLegByJob?.length}`);
      if (selectElement) {
        selectElement.focus();
      }
    }, 100);
  }

  const removeJobLeg = (i: any) => {
    const data = (watchQs || [])?.filter((item: any, index: any) => i != index);

    const obj = getValues()
    obj.jobs = data

    setJobLegByJob(data)
    reset(obj)
  }

  function getDatesBetween(data: any) {
    const start = new Date(data?.start_at_date);
    const end = new Date(data?.end_at_date);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Map selected days to their respective day indices
    const selectedDaysIndices = data?.selected_days.map((day: string) => daysOfWeek.indexOf(day));

    const dateArray = [];
    let currentDate = start;

    const allcationData = data?.allocation?.map((item: any) => {
      return { driver_id: item?.driver?.value || null, vehicle_id: item?.vehicle?.value || null, driver_vehicles_id: item?.driver_vehicles_id || null }
    })

    const defaultDriver = {label : 'Select Driver', value : null}
    const defaultVehicle = {label : 'Select Vehicle', value : null}
    const defaultAllocationForm = {
      driver : defaultDriver,
      vehicle : defaultVehicle
    }

    // Loop through the dates, incrementing by one day each time
    while (currentDate <= end) {
      if (selectedDaysIndices.includes(currentDate.getDay())) {
        const obj: any = {
          status: {id: 1, label: "New"},
          pick_up_location: data?.pick_up_location,
          drop_off_location: data?.drop_off_location,
          start_at_date: moment(currentDate).format('YYYY-MM-DD'),
          end_at_date: moment(currentDate).format('YYYY-MM-DD'),
          start_at_time: data?.start_at_time,
          end_at_time: data?.end_at_time,
          pax: data?.pax,
          vehicles_required: data?.vehicles_required,
          cost: data?.charge,
          job_reference: data?.job_reference,
          driver_vehicles_arr: allcationData?.length ? allcationData : [defaultAllocationForm]
        }
        dateArray.push(obj); // Add a copy of the date to avoid referencing issues

         // Create a return leg if return_leg is true
        if (data?.return_leg) {
          const returnJob = {
            ...obj,
            pick_up_location: data.return_pick_up_location, // Swap locations
            drop_off_location: data.return_drop_off_location, // Swap locations
            start_at_time: data.return_start_at_time, // Swap time
            end_at_time: data.return_end_at_time // Swap time
          };
          dateArray.push(returnJob); // Add the return leg job
        }
      }
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return dateArray;
  }
  
  const appendRecurringJobLeg = (data: any) => {
    const dates = getDatesBetween(data);
    setJobLegByJob(pre => [...pre, ...dates])
    setValue('jobs', [...(getValues('jobs')) || [], ...dates])
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event?.keyCode != 13 && event?.keyCode != 9) {
        if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target as Node)) {
        } else {
          if (searchRef.current === '') {
            setSearchValue(searchRef.current);
            setMenuIsOpen(false);
            setCurrentCustomer({ value: '', label: searchRef.current, email: '' });
            setValue('customer', { value: '', label: searchRef.current });
          }
        }
      }
    };

    document.addEventListener("keyup", handleClickOutside);
    return () => {
      document.removeEventListener("keyup", handleClickOutside);
    };
  }, [searchRef]);

  // useEffect(() => {
  //   if (searchValue) {
  //     setClientOptions([]);
  //     onInputChange(searchValue)
  //     setMenuIsOpen(true); 
  //   }
  // }, [searchBy]);

  const onInputChange = (newInputValue: string) => {
    searchRef.current = newInputValue;
     if (newInputValue) {
       if (debounceTimeoutRef.current) {
         clearTimeout(debounceTimeoutRef.current);
       }
 
       debounceTimeoutRef.current = setTimeout(() => {
         if (newInputValue.trim()) {
           getOptionsAsync(newInputValue); // Call the API
         }
       }, 1000); // 1-second debounce

      setIsLoading(true);
      setMenuIsOpen(true);
      setCustomerSearch(newInputValue)
      setSearchValue(newInputValue);
    }
  };

  const getOptionsAsync = (inputValue: string) => {
    const obj = { 
      getall: 1,
      fields: `id,customer_name,customer_email`,
      // fields: `id,${searchBy}`,
      // searchByName:searchBy,
      customer_name : inputValue
    }

    CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        const data = res?.data?.response?.map((item: any) => ({
          value: item.id,
          label: item?.customer_name,
          email: item?.customer_email,
          bill_to_id: item?.invoice_to_customer_id,
          bill_to_name: item?.invoice_to_customer_name,
          // search_by_client: item?.search_by_client
          // label: item?.[searchBy], 
        }));
        setClientOptions(data || []);
      })
      .catch(() => {
        setClientOptions([]);
      })
      .finally(() => {
        setIsLoading(false); // Hide loading indicator
      });
  };

  const handleConfirmationEmail = () => {
    setLoader('confirmation_loader')
    const data = {
      booking_id: id
    }
    JobService.confirmationEmail(entity_id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)
      })
      .catch((err: any) => toast.error(err?.response?.data?.message))
      .finally(() => setLoader(null));
  }

  const loadOptions = (inputValue: any, callback: any) => {
    if (customerTimeoutId) {
      clearTimeout(customerTimeoutId);
    }
    customerTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,customer_name',
        customer_name : inputValue
      }
      CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.customer_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  const forceCancelBooking = () => {
    setLoader('cancel_loader')
    const data = {
      label: 'booking'
    }
    JobService.cancelBookingOrJob(entity_id, id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setBookingCancelModal(false)
        editData()
      })
      .catch((err: any) => toast.error(err?.response?.data?.message))
      .finally(() => setLoader(null));
  }

  const getBookingHistory = () => {
    setLoader('booking_history')
    JobService.getBookingHistory(entity_id, id)
    .then((res: any) => {
      const data = [];
      Object.values(res?.data)?.map((item: any) => {
        item?.map((sub: any) => {
          data.push(sub)
        })
      })
      setHistoryData(data)
    })
    .catch((err: any) => toast.error(err?.response?.data?.message))
    .finally(() => setLoader(null));
  } 

  useEffect(() => {
    if (openHistoryModal) getBookingHistory()
    else setHistoryData([])
  }, [openHistoryModal])

  const handleEvent = (item: any) => {
    setEmailPreview({ isOpen: true,  value: null })
    JobService.getJobEmail(entity_id, item?.email_id)
    .then((res: any) => {
      setEmailPreview({ isOpen: true,  value: res?.data?.response?.mail_body })
    })
    .catch((err: any) => toast.error(err?.response?.data?.message))
    .finally(() => setLoader(null));
  }

  return (
    <>
      <Fragment>
        <Offcanvas 
          isOpen={openCustomerModel} 
          toggle={() => setOpenCustomerModel(false)}
          direction='end'
          style={{width: '700px'}}
        >
          <OffcanvasHeader toggle={() => setOpenCustomerModel(false)}>
            <h2>Clients</h2>
          </OffcanvasHeader>
          <OffcanvasBody className='p-0'>
            <AddCustomer customerSearch={customerSearch} searchBy={searchBy} setSearchBy={setSearchBy} setOpenCustomerModel={setOpenCustomerModel} setCustomerName={setCustomerName} setMenuIsOpen={setMenuIsOpen} />
          </OffcanvasBody>
        </Offcanvas>
        <ConfirmModal
          isOpen={emailPreview?.isOpen}
          onHide={() => setEmailPreview({ isOpen: false,  value: null })}
          yes={false}
          no='Close'
          size={'modal-lg'}
          loader={pending}
          title={`Email Preview`}
          body={
            <ReactQuill
              theme="snow"
              value={emailPreview?.value}
              readOnly={true}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["blockquote"]
                  ]
                },
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "link",
                "image"
              ]}
            />
          }
        />
        <ConfirmModal
          isOpen={openDeleteJobModal}
          onHide={() => setOpenDeleteJobModal(false)}
          ondelete={deleteJob}
          loader={pending}
          title={`Delete Job Leg ?`}
          body={`Are you sure you wish to delete this Job Leg ?`}
        />
        <ConfirmModal
          isOpen={centeredModal}
          onHide={() => setCenteredModal(false)}
          ondelete={forceDeleteBooking}
          loader={pending}
          title={`Delete Booking ${bookingData?.booking_number}`}
          body={`Are you sure you wish to delete this booking ? The booking and any associated jobs will be removed and cannot be restored.`}
        />
        <ConfirmModal
          isOpen={bookingCancelModal}
          onHide={() => setBookingCancelModal(false)}
          ondelete={forceCancelBooking}
          loader={pending}
          title={`Cancel Booking`}
          yes='Yes, Cancel'
          no='No'
          body={`Are you sure you wish to Cancel this booking ?`}
        />
        <CopyBookingModal 
          id={id}
          bookingData={bookingData}
          openCopyModal={openCopyModal}
          setOpenCopyModal={setOpenCopyModal}
        />
        <HistoryModal
          loader={loader}
          openHistoryModal={openHistoryModal}
          setOpenHistoryModal={setOpenHistoryModal}
          title={`Booking Number ${bookingData?.booking_number}`}
          tableHearder={['Timestamp', 'User', 'Action']}
          keys={['timestamp', 'user', 'text']}
          width={['100px', '100px', '300px']}
          historyData={historyData}
          clickEvent={[false, false, true]}
          openEmail={handleEvent}
        />
        <AddRecurringJob
          openRecurringJobModal={openRecurringJobModal}
          setOpenRecurringJobModal={setOpenRecurringJobModal}
          setRenderElementService={setRenderElementService}
          bookingId={id}
          recurringJobId={recurringJobId}
          appendRecurringJobLeg={appendRecurringJobLeg}
          fetchFormData={fetchFormData}
          priceType={priceType}
          recurring_job_settings={recurring_job_settings}
          setRecurring_job_settings={setRecurring_job_settings}
        />
        <ConfirmationEmail
          openConfirmationEmail={openConfirmationEmail}
          setOpenConfirmationEmail={setOpenConfirmationEmail}
          currentCustomer={currentCustomer}
        />
        <Card className='p-10 w-100' >
          <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
            <Form onSubmit={handleSubmit(fetchFormData)}>
              <Row className='w-100'>
                  {id &&
                    <Col md='2' className='mb-8 mt-2'>
                      <Label className='form-label' for='booking_number'>Booking ID</Label>
                      <Controller name='booking_number' control={control} rules={{required: false}} defaultValue=''
                        render={({ field }) => <Input placeholder='Enter Booking ID' disabled invalid={ errors.booking_number && false } {...field} />} 
                      />
                      {errors.booking_number && (<small className="text-danger ml-2 ">Booking ID is a required field</small>)}
                    </Col>
                  }
                  {!id ?
                    <>
                      <Col md='4'>
                        <Col md='12' className='mb-2 d-flex'>
                          <Label className='form-label me-5' for='customer'>Client</Label>
                        </Col>
                        <div ref={selectWrapperRef}>
                          <Controller name='customer' control={control} rules={{required: true}} defaultValue=''
                            render={({ field: { onChange } }) => 
                              <ReactSelect
                                className='react-select w-100 booking_zindex'
                                ref={asyncSelectRef}
                                inputValue={searchValue}  // Set the input value
                                onInputChange={onInputChange}  // Trigger the API call on input change
                                options={clientOptions}  // Set the options dynamically from the API response
                                isLoading={isLoading}  // Show a loading spinner while data is being fetched
                                placeholder="Search Client"
                                isClearable={true}
                                value={currentCustomer}
                                styles={customSelectStyles(null)}
                                theme={selectThemeColors}
                                onChange={(data: any) => {
                                  onChange(data)
                                  setSearchBy(data?.search_by_client === 1 ? 'company_name' : data?.search_by_client === 0 ? 'customer_name' : 'customer_name')
                                  setCurrentCustomer(data)
                                  setSearchValue(data ? data.label : "");
                                  setMenuIsOpen(false);
                                  setCurrentBillTo({ value: data?.bill_to_id, label: data?.bill_to_name })
                                  asyncSelectRef.current.blur();
                                  asyncSelectRef.current.focus();
                                }}
                                menuIsOpen={menuIsOpen}
                                noOptionsMessage={({ inputValue }) =>
                                  inputValue ? <div onClick={() => setOpenCustomerModel(true)} className='cursor-pointer' style={{color: 'rgb(89, 89, 196)', display: 'flex', justifyContent: 'start'}}>
                                                  <i className="fa-solid fa-plus me-3 d-flex align-items-center" style={{color: 'rgb(89, 89, 196)'}}></i>
                                                  Add New <b className='m-0 ms-2'>{customerSearch}</b>
                                                </div> : 'Start typing to search...'
                                }
                                filterOption={null} // Disable internal filtering (critical fix)
                              />
                            }
                          />
                          {errors?.customer && (<small className="text-danger ml-2 ">Customer is a required field</small>)}
                        </div>
                      </Col>
                      <Col md={2} className='mb-8 mt-2'>
                        <Label className='form-label' for='job_name'>Booking Name (Optional)</Label>
                        <Controller 
                          name='job_name' 
                          control={control} 
                          rules={{ required: false }} 
                          defaultValue=''
                          render={({ field }) => (
                            <Input 
                              type='text' 
                              placeholder='Enter Booking Name' 
                              {...field}
                            />
                          )}
                        />
                      </Col>
                      <Col md={2} className='mb-8 mt-2'>
                        <Label className='form-label' for='bill_to'>Bill To</Label>
                        <Controller 
                          name='bill_to' 
                          control={control} 
                          rules={{ required: false }} 
                          defaultValue=''
                          render={({ field }) => (
                            <AsyncSelect
                              isClearable={true}
                              className='react-select w-100 booking_zindex_status'
                              classNamePrefix='select'
                              placeholder='Select'
                              isRtl={false}
                              name='callback-react-select'
                              theme={selectThemeColors}
                              styles={customSelectStyles(null)}
                              loadOptions={loadOptions}
                              value={currentBillTo}
                              cacheOptions
                              onChange={(data: any) => {
                                field.onChange(data)
                                setCurrentBillTo(data)
                              }}
                            />
                          )}
                        />
                      </Col>
                    </> :
                    <>
                      <Col md={2} className='mb-8 mt-2'>
                        <Label className='form-label' for='job_name'>Booking Name (Optional)</Label>
                        <Controller 
                          name='job_name' 
                          control={control} 
                          rules={{ required: false }} 
                          defaultValue=''
                          render={({ field }) => (
                            <Input 
                              type='text' 
                              placeholder='Enter Booking Name' 
                              {...field}
                            />
                          )}
                        />
                      </Col>
                      <Col md='2' className=''>
                        <Col md='12' className='mb-2 d-flex'>
                          <Label className='form-label me-5' for='customer'>Client</Label>
                        </Col>
                        <div ref={selectWrapperRef}>
                          <Controller name='customer' control={control} rules={{required: true}} defaultValue=''
                            render={({ field: { onChange } }) => 
                              <ReactSelect
                                className='react-select w-100 booking_zindex'
                                ref={asyncSelectRef}
                                inputValue={searchValue}  // Set the input value
                                onInputChange={onInputChange}  // Trigger the API call on input change
                                options={clientOptions}  // Set the options dynamically from the API response
                                isLoading={isLoading}  // Show a loading spinner while data is being fetched
                                placeholder="Search Client"
                                isClearable={true}
                                value={currentCustomer}
                                styles={customSelectStyles(null)}
                                theme={selectThemeColors}
                                onChange={(data: any) => {
                                  onChange(data)
                                  setSearchBy(data?.search_by_client === 1 ? 'company_name' : data?.search_by_client === 0 ? 'customer_name' : 'customer_name')
                                  setCurrentCustomer(data)
                                  setSearchValue(data ? data.label : "");
                                  setMenuIsOpen(false);
                                  setCurrentBillTo({ value: data?.bill_to_id, label: data?.bill_to_name })
                                  asyncSelectRef.current.blur();
                                  asyncSelectRef.current.focus();
                                }}
                                menuIsOpen={menuIsOpen}
                                noOptionsMessage={({ inputValue }) =>
                                  inputValue ? <div onClick={() => setOpenCustomerModel(true)} className='cursor-pointer' style={{color: 'rgb(89, 89, 196)', display: 'flex', justifyContent: 'start'}}>
                                                  <i className="fa-solid fa-plus me-3 d-flex align-items-center" style={{color: 'rgb(89, 89, 196)'}}></i>
                                                  Add New <b className='m-0 ms-2'>{customerSearch}</b>
                                                </div> : 'Start typing to search...'
                                }
                                filterOption={null} // Disable internal filtering (critical fix)
                              />
                            }
                          />
                          {errors?.customer && (<small className="text-danger ml-2 ">Customer is a required field</small>)}
                        </div>
                      </Col>
                      <Col md={2} className='mb-8 mt-2'>
                        <Label className='form-label' for='bill_to'>Bill To</Label>
                        <Controller 
                          name='bill_to' 
                          control={control} 
                          rules={{ required: false }} 
                          defaultValue=''
                          render={({ field }) => (
                            <AsyncSelect
                              isClearable={true}
                              className='react-select w-100 booking_zindex_status'
                              classNamePrefix='select'
                              placeholder='Select'
                              isRtl={false}
                              name='callback-react-select'
                              theme={selectThemeColors}
                              styles={customSelectStyles(null)}
                              loadOptions={loadOptions}
                              value={currentBillTo}
                              cacheOptions
                              onChange={(data: any) => {
                                field.onChange(data)
                                setCurrentBillTo(data)
                              }}
                            />
                          )}
                        />
                      </Col>
                    </>
                  }
                  <Col md='4' className='mb-8 mt-2'>
                    <Label className='form-label' for='status'>Status</Label>
                    <Controller name='status' control={control} rules={{required: true}} defaultValue=''
                      render={({ field: { onChange } }) => 
                        <ReactSelect
                          isClearable={false}
                          className='react-select booking_zindex_status'
                          classNamePrefix='select'
                          theme={selectThemeColors}
                          styles={customSelectStyles(errors.status)}
                          isDisabled={true}
                          options={statusData}
                          value={currentStatus}
                          onChange={(data: any) => {
                            onChange(data)
                            setCurrentStatus(data)
                          }}
                        />
                      }
                    />
                    {errors?.status && (<small className="text-danger ml-2 ">Status is a required field</small>)}
                  </Col>
                  <Col className='col-2 mb-8 mt-2'>
                    <Label className='form-label' for='charge_type'>Charge Type</Label>
                    <ReactSelect
                      isClearable={false}
                      className='react-select'
                      classNamePrefix='select'
                      theme={selectThemeColors}
                      styles={customSelectStyles(null)}
                      options={priceTypeData}
                      value={priceType}
                      onChange={(data: any) => {
                        setPriceType(data)
                        if (data?.value === 0) {
                          setValue('price', bookingData?.job_price || 0)
                          setJobPrice(bookingData?.job_price || 0)
                        } else if (data?.value === 1) {
                          setValue('price', bookingData?.job_leg_sum || 0)
                          setJobPrice(bookingData?.job_leg_sum || 0)
                        }
                      }}
                    />
                  </Col>
                  <Col className='col-2 mb-8 mt-2'>
                    <Label className='form-label' for='price'>Charge</Label>
                    <Controller name='price' control={control} rules={{ required: false }} defaultValue=''
                      render={({ field }) => (
                        <Input type='number' min={0} placeholder='Enter Charge' value={jobPrice} 
                          disabled={priceType?.value === 1} 
                          invalid={ errors.price && true } 
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setJobPrice(e.target.value)} 
                          }
                        />
                      )}
                    />
                    {errors.price && (<small className="text-danger ml-2">Charge is a required field</small>)}
                  </Col>
                  <Col className='col-2 mb-8 mt-2'>
                    <Label className='form-label' for='price'>Booked By</Label>
                    <Controller name='booked_by' control={control} rules={{ required: false }} defaultValue=''
                      render={({ field }) => (
                        <Input type='text' placeholder='Enter Booked by' {...field} />
                      )}
                    />
                  </Col>
                  <Col className='col-2 mb-8 mt-2'>
                    <Label className='form-label' for='tag'>Tag Jobs</Label>
                    <ReactSelect
                      isMulti
                      id='task-tags'
                      className='react-select booking_zindex'
                      classNamePrefix='select'
                      isClearable={false}
                      isSearchable={false}
                      options={tagData}
                      theme={selectThemeColors}
                      value={currentTag}
                      onChange={data => {
                        setCurrentTag(data as TagOption[])
                      }}
                    />
                  </Col>
                  <Col className={`col-2 mb-8 mt-2`}>
                    <Label className='form-label' for='price'>Purchase Order</Label>
                    <Controller name='purchage_order' control={control} rules={{ required: false }} defaultValue=''
                      render={({ field }) => (
                        <Input type='text' min={0} placeholder='Enter Purchase Order' value={purchaseOrder} 
                          invalid={ errors.price && true } 
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setPurchaseOrder(e.target.value)} 
                          }
                        />
                      )}
                    />
                    {errors.price && (<small className="text-danger ml-2">Purchase Order is a required field</small>)}
                  </Col>
                  {id &&
                    <Col className={`col-2 mb-8 mt-2`}>
                      <Label className='form-label' for='price'>Booking Deposit</Label>
                      <Controller name='booking_deposit' control={control} rules={{ required: false }} defaultValue=''
                        render={({ field }) => (
                          <Input type='number' disabled={true} {...field} />
                        )}
                      />
                    </Col>
                  }
                  {!id &&
                    <Col className='col-2 mb-8 mt-2'>
                      <Label className='form-label' for='price'>Job Types</Label>
                        <ReactSelect
                          className='react-select'
                          classNamePrefix='select'
                          isClearable={false}
                          isSearchable={false}
                          options={jobTypesList}
                          styles={customSelectStyles(null)}
                          theme={selectThemeColors}
                          value={currentJobType}
                          onChange={data => {
                            setCurrentJobType(data)
                          }}
                        />
                    </Col>
                  }
                  <Col md='6' className='mb-8'>
                    <Label className='form-label' for='notes'>Booking Notes (Visible to Clients)</Label>
                    <Input type='textarea' rows='2' placeholder='Enter Notes' value={notes} onChange={(e) => setNotes(e.target.value)} />
                  </Col>
                  <Col md='6' className='mb-8'>
                    <Label className='form-label' for='internal_notes'>Internal Notes</Label>
                    <Input type='textarea' rows='2' placeholder='Enter Notes' value={internal_notes} onChange={(e) => setInternal_notes(e.target.value)} />
                  </Col>
              </Row>
              { jobLegByJob?.length ?
                <Row>
                  <div className='job_table mt-3' style={{ maxHeight: '40vh', minHeight: '300px', overflowY: 'scroll' }}>
                    <Table className='w-100 job_table'>
                      <thead style={{zIndex: 2}}>
                        <tr>
                        <th className='ps-5' style={{width: '110px'}}>Job ID</th>
                          <th >Pick Up</th>
                          <th >Drop Off</th>
                          <th style={{width: '175px'}}>Start</th>
                          <th style={{width: '175px'}}>End</th>
                          <th style={{width: '80px'}}>PAX</th>
                          <th style={{width: '70px'}}>Vehicles</th>
                          { priceType?.value === 1 && <th style={{width: '80px'}}>Cost</th> }
                          <th style={{width: '200px'}}>Ref</th>
                          <th >Notes</th>
                          <th style={{width: '30px'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobLegByJob?.map((item: any, i: any) => {
                          // const pax = item?.pax == null || item?.pax == 'null' ? 'N/A' : item?.pax; 
                          return (
                            <tr key={i}>
                              <td className='px-5 ' style={{width: '110px'}} onClick={() => item?.id ?  navigate(`/jobs/${item?.id}/edit?booking=${id}`) : null}>
                                <div style={{maxWidth: '140px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} className='fw-bold cursor-pointer theme-color'>
                                  {item?.job_number}
                                </div>
                                <div>{item?.status?.label}</div>
                              </td>
                                {/* <div className='pt-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item?.pick_up_location}</div> */}
                              {/* <td style={{width: '250px'}}>
                                <Controller name={`jobs.${i}.pick_up`} control={control} defaultValue=''
                                  render= {({ field }) => (
                                    <AsyncSelect
                                      isClearable={true}
                                      className='react-select w-100'
                                      classNamePrefix='select'
                                      placeholder='Search From'
                                      isRtl={false}
                                      name='callback-react-select'
                                      loadOptions={loadLocationOptions}
                                  
                                      {...field}
                                      cacheOptions
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                        
                                      }}
                                      noOptionsMessage={() => 'No options'}
                                    />
                                  )}
                                /> 
                              </td> */}
                              <td style={{width: '200px'}}>
                                <Controller 
                                  name={`jobs.${i}.pick_up_location`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <AsyncCreatableSelect
                                      inputId={`pick-up-locations${i}`}
                                      {...field}
                                      className='react-select'
                                      classNamePrefix='select'
                                      isClearable={false}
                                      isSearchable={true}
                                      styles={customSelectStyles(null)}
                                      loadOptions={loadLocationOptions}
                                      theme={selectThemeColors}
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              <td style={{width: '200px'}}>
                                <Controller 
                                  name={`jobs.${i}.drop_off_location`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <AsyncCreatableSelect
                                      id='task-tags'
                                      {...field}
                                      className='react-select'
                                      classNamePrefix='select'
                                      isClearable={false}
                                      isSearchable={true}
                                      styles={customSelectStyles(null)}
                                      loadOptions={loadLocationOptions}
                                      theme={selectThemeColors}
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              {/* <td style={{width: '250px'}}>
                                <Controller name={`jobs.${i}.drop_off`} control={control} defaultValue=''
                                  render= {({ field }) => (
                                    <AsyncSelect
                                      isClearable={true}
                                      className='react-select w-100'
                                      classNamePrefix='select'
                                      placeholder='Search drop'
                                      isRtl={false}
                                      name='callback-react-select'
                                      loadOptions={loadLocationOptions}
                                      {...field}
                                      cacheOptions
                                      onChange={(data: any) => {
                                        field.onChange(data)
                                      }}
                                      noOptionsMessage={() => 'No options'}
                                    />
                                  )}
                                /> 
                              </td> */}
                                {/* <div className='pt-3' style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item?.drop_off_location}</div> */}
                              <td style={{width: '175px'}}>
                                <div className='d-flex'>
                                  <Controller name={`jobs.${i}.start_at_date`} control={control} defaultValue=''
                                    render= {({ field }) => (
                                      <Flatpickr 
                                        className='form-control cursor-pointer me-1'
                                        placeholder='Select Start Date'
                                        {...field}
                                        options={{ 
                                          enableTime: false, 
                                          altInput: true, 
                                          dateFormat: 'Y-m-d H:i',
                                          altFormat: "d/m/Y",
                                          disableMobile : true
                                        }}
                                        onChange={(value) => {
                                          field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                          if (moment(value[0]).format('YYYY-MM-DD') > getValues(`jobs.${i}.end_at_date`)) {
                                            setValue(`jobs.${i}.end_at_date`, moment(value[0]).format('YYYY-MM-DD'))
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                  <Controller name={`jobs.${i}.start_at_time`} control={control} defaultValue=''
                                    render= {({ field }) => (
                                      <Input type='time' className='cursor-pointer'
                                        {...field} 
                                        style={{width: '80px'}}
                                        onChange={(e) => { 
                                          field.onChange(e.target.value)
                                          if (e.target.value > getValues(`jobs.${i}.end_at_time`)) {
                                            setValue(`jobs.${i}.end_at_time`, e.target.value)
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td style={{width: '175px'}}>
                                <div className='d-flex'>
                                  <Controller name={`jobs.${i}.end_at_date`} control={control} defaultValue=''
                                    render= {({ field }) => (
                                      <Flatpickr 
                                        className='form-control cursor-pointer me-1' 
                                        placeholder='Select end Date'
                                        {...field}
                                        options={{ 
                                          enableTime: false, 
                                          altInput: true, 
                                          minDate: watchQs[i].start_at_date,
                                          dateFormat: 'Y-m-d H:i',
                                          altFormat: "d/m/Y",
                                          disableMobile : true
                                        }}
                                        onChange={(value) => {
                                          field.onChange(moment(value[0]).format('YYYY-MM-DD'))
                                        }}
                                      />
                                    )}
                                  />
                                  <Controller name={`jobs.${i}.end_at_time`} control={control} defaultValue=''
                                    render= {({ field }) => (
                                      <Input type='time' className='cursor-pointer'
                                        {...field} 
                                        style={{width: '80px'}}
                                        onChange={(e) => { 
                                          field.onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td style={{width: '80px'}}>
                                <Controller
                                  name={`jobs.${i}.pax`}
                                  control={control}
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter PAX' type='number' min={0}
                                      onKeyDown={(e) => preventDefualt(e)}
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e.target.value)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              <td style={{width: '70px'}}>
                                <Controller 
                                  name={`jobs.${i}.vehicles_required`} 
                                  control={control} 
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter Vehicles Required' type='number' min={1}
                                      onKeyDown={(e) => preventDefualt(e)}
                                      {...field} 
                                      onFocus={(e) => (e.target as any).select()}
                                      onChange={(e) => { 
                                        // if (e.target.value === '') {
                                        //   e.preventDefault();
                                        //   return false;
                                        // }
                                        field.onChange(e.target.value)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              {priceType?.value === 1 &&
                                <td style={{width: '80px'}}>
                                  <Controller 
                                    name={`jobs.${i}.cost`} 
                                    control={control} 
                                    defaultValue=''
                                    render={({ field }) => (
                                      <Input placeholder='Enter Cost' type='number' min={0}
                                        onKeyDown={(e) => preventDefualt(e)}
                                        {...field} 
                                        onFocus={(e) => (e.target as any).select()}
                                        onChange={(e) => { 
                                          // if (e.target.value === '') {
                                          //   e.preventDefault();
                                          //   return false;
                                          // }
                                          field.onChange(e.target.value)
                                        }}
                                      />
                                    )}
                                  />
                                </td>
                              }
                              <td style={{width: '200px'}}>
                                <Controller
                                  name={`jobs.${i}.job_reference`}
                                  control={control}
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter Ref' type='text'
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e.target.value)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              <td>
                                <Controller
                                  name={`jobs.${i}.notes`}
                                  control={control}
                                  defaultValue=''
                                  render={({ field }) => (
                                    <Input placeholder='Enter notes' type='text'
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e.target.value)
                                      }}
                                    />
                                  )}
                                />
                              </td>
                              <td style={{width: '30px'}}> 
                                {id ?
                                  <div className='column-action pt-3'>
                                    <UncontrolledDropdown>
                                      <DropdownToggle tag='span'>
                                        <Plus size={18} className='cursor-pointer' />
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                        <DropdownItem className='w-100 dropdownItem' onClick={() => createCloneJob(item, 'add_job')}>
                                          <span className='align-middle'>Add New Leg</span>
                                        </DropdownItem>
                                        <DropdownItem className='w-100 dropdownItem' onClick={() => createCloneJob(item, 'return_job')}>
                                          <span className='align-middle'>Add Return Leg</span>
                                        </DropdownItem>
                                        <DropdownItem className='w-100 dropdownItem' onClick={() => deleteJobConfirmation(item?.id)}>
                                          <span className='align-middle'>Delete Leg</span>
                                        </DropdownItem>
                                        <DropdownItem className='w-100 dropdownItem' onClick={() => createRecurring(item?.id)}>
                                          <span className='align-middle'>Add Recurring</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  : 
                                  <div className='pt-3'>
                                    <IconComponent
                                      iconName='cross'
                                      className='fs-3'
                                      iconType={undefined} 
                                      onClick={() => removeJobLeg(i)}
                                      style={{
                                        border: '1px solid rgb(255, 128, 0)',
                                        // backgroundColor: 'rgb(253, 240, 215)',
                                        borderRadius: '10px',
                                        color: 'rgb(255, 128, 0)',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  </div>
                                }
                                </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
                : ''
              }
              {(id && jobLegByJob?.length === 0) && (
                <>
                  <div className='d-flex justify-content-end me-5'>
                    <Plus size={18} style={{cursor: createJobLoader ? 'not-allowed' : 'pointer'}} onClick={createNewJob} />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <p className='md-2 py-50 mt-2'>There are no Jobs to display</p>
                  </div>
                </>
              )}
              <div className='d-flex justify-content-between mt-10'>
                {!id &&
                  <Row>
                      <div>
                        {currentJobType?.value === 1 &&
                          <Button size='sm' type="button" color="primary" className='me-3' onClick={appendJobLeg}>Add Job Leg</Button>
                        }
                        {currentJobType?.value === 2 &&
                          <Button size='sm' type="button" color="primary" onClick={() => setOpenRecurringJobModal(true)}>Add Recurring Job Legs</Button>
                        }
                      </div>
                  </Row>
                }
                <div>
                  { id && 
                    <>
                      <Button type="button" color="primary" disabled={bookingData?.payment_status?.id == 1} onClick={() => setOpenInvoice(true)} >
                        Invoice
                      </Button>
                      <Button type="button" color="primary" className='mx-2' disabled={loader === 'confirmation_loader'} onClick={() => setOpenConfirmationEmail(true)}>
                        { loader === 'confirmation_loader' ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Confirmation Email</span>}
                      </Button>
                      <Button type="button" color="primary" disabled={bookingData?.payment_status?.id == 1} onClick={() => setDepositModal({ isOpen: true, booking_id: id, customer_id: currentCustomer?.value })} >
                        Add Deposit
                      </Button>
                      <Button type="button" color="primary" className='mx-2' onClick={() => setOpenCopyModal(true)}>
                        Copy
                      </Button>
                      <Button type="button" color="primary" onClick={() => setOpenHistoryModal(true)}>
                        History
                      </Button>
                      <Button type="button" color="danger" className='mx-2' onClick={() => setBookingCancelModal(true)}>
                        Cancel
                      </Button>
                      {currentUser?.level == 1 &&
                        <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                          Delete
                        </Button>
                      }
                    </>
                  }
                </div>
                <div>
                  <Button type="button" color="secondary" onClick={() => navigate("/booking")} style={{ marginRight: "10px" }}>
                    Close
                  </Button>
                  <Button type='submit' color='primary' disabled={loader === 'create' || (!id && jobLegByJob?.length === 0)} className='btn-next'>
                    { loader === 'create' ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </UILoader>
        </Card>
        <InvoiceModel 
          openInvoice={openInvoice}
          setOpenInvoice={setOpenInvoice}
          bookingData={bookingData}
          setRenderElementService={setRenderElementService}
        />
        <BookingDeposit 
          depositModal={depositModal}
          setDepositModal={setDepositModal}
          setRenderElementService={setRenderElementService}
        />
      </Fragment>
    </>
  )
}

export {AddJob}