import React, { Fragment, useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon } from '../../../_metronic/helpers';
import UILoader from '../../modules/commonModel/Ui-loader';
import { InvoiceService } from '../../modules/Services/Invoice';
import toast from 'react-hot-toast';
import InvoiceModel from '../Invoices/InvoiceModel';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';
import moment from 'moment';
import { Button, Row, Spinner, Table } from 'reactstrap';
import axios from 'axios';
import { formatCurrency } from '../../modules/commonModel/Utils';

function CustomerInvoiceTab({ customer_id, renderInvoice }) {
    const entity_id = localStorage.getItem('entity_id')
    const [pending, setPending] = useState(false)
    const [invoiceData, setInvoiceData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [openInvoice, setOpenInvoice] = useState(false)
    const [invoiceId, setInvoiceId] = useState(false)
    const [renderElementService, setRenderElementService] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [loader, setLoader] = useState(null)

    const getInvoice = () => {
        if (customer_id) {
            setPending(true)
            InvoiceService.getClientInvoiceList(entity_id, {
                per_page: 20,
                page: currentPage,
                customer_id: customer_id
            })
                .then((res: any) => {
                    if (currentPage > 1) setInvoiceData(pre => [...pre, ...res?.data?.response?.data])
                    else setInvoiceData(res?.data?.response?.data)
                    setNextPage(res?.data?.response?.next_page_url)
                })
                .catch((err: any) => {
                    console.log(err?.response?.data?.message)
                })
                .finally(() => setPending(false))
        }
    }

    function downloadInvoicePdf(invoice_id: any) {
        InvoiceService.downloadInvoicePdf(entity_id, invoice_id)
            .then((res: any) => {
                toast.success(res?.data?.message)
                window.open(res?.data?.url, '_blank', 'noopener,noreferrer')
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        if (renderElementService) {
            setRenderElementService(0)
            getInvoice()
        }
    }, [renderElementService])

    useEffect(() => {
        if (renderInvoice) {
            getInvoice()
        }
    }, [renderInvoice])

    function handleScroll(event: any) {
        const scrollHeight = event.target.scrollTop + event.target.clientHeight
        const elementHeight = event.target.scrollHeight
        if (event.target.scrollTop > 0) {
            if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
                setCurrentPage((p: any) => p + 1)
                setRenderElementService((prev) => prev + 1)
            }
        }
    }

    const generate_statement = () => {
        setLoader('download_statement')
        InvoiceService.downloadInvoiceStatement(entity_id, customer_id)
            .then(async (res: any) => {
                toast.success(res?.data?.message)
                window.open(res?.data?.url, '_blank', 'noopener,noreferrer')

                // const fileURL = res?.data?.url;
                // const fileName = 'pdf-download.pdf';

                // const link = document.createElement('a');
                // link.href = fileURL;

                // // Suggest a file name for the downloaded file
                // link.download = fileName;

                // // Handle Safari browsers
                // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                // if (isSafari) {
                //     window.location.href = fileURL;
                // } else {
                //     // Simulate a click to trigger the download
                //     const event = new MouseEvent('click', {
                //     view: window,
                //     bubbles: true,
                //     cancelable: false,
                //     });
                //     link.dispatchEvent(event);
                // }

                // // Cleanup the URL if needed
                // URL.revokeObjectURL(link.href);
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(null))
    }

    return (
        <Fragment>
            <InvoiceModel
                openInvoice={openInvoice}
                setOpenInvoice={setOpenInvoice}
                invoiceId={invoiceId}
                setInvoiceId={setInvoiceId}
                setRenderElementService={setRenderElementService}
                bookingData={null}
            />
            <UILoader blocking={pending} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
                <Row>
                    <div className='d-flex justify-content-end py-4 px-7'>
                        <Button type='button' size='sm' color='primary'
                            disabled={loader === 'download_statement'}
                            onClick={generate_statement}
                        >
                            { loader === 'download_statement' ?
                                <div className='align-middle d-sm-inline-block d-none'>
                                    <Spinner size='sm'/> <span className='ms-2'>Generate Statement</span>
                                </div>
                                :
                                <span className='align-middle d-sm-inline-block d-none'>Generate Statement</span>
                            }
                        </Button>
                    </div>
                </Row>
                <div onScroll={(e) => handleScroll(e)} id='booking_table' className='invoice-table-style'>
                    <Table className='jobs-table'>
                        <thead className='sticky-header' style={{ zIndex: '2' }}>
                            <tr style={{ fontWeight: '500' }}>
                                <th className='ps-3'>Invoice Number</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th className='text-end'>Amount</th>
                                <th className='text-end'>Paid</th>
                                <th className='text-end'>Balance</th>
                                <th className='text-end pe-3'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceData?.map((item: any, i: any) => {
                                return (
                                    <tr key={i} style={{ borderBottom: '1px solid rgba(0, 0, 0, .12)' }}>
                                        <td className='ps-3'>
                                            <span className='fw-bold cursor-pointer theme-color' onClick={() => { setInvoiceId(item?.id); setOpenInvoice(true) }}>
                                                {item?.invoice_number}
                                            </span>
                                        </td>
                                        <td>
                                            <div>{item?.date ? moment(item?.date).format('DD-MM-YYYY') : ''} </div>
                                        </td>
                                        <td>
                                            <div>{item?.status?.label} </div>
                                        </td>
                                        <td style={{width: '120px'}}>
                                            <div className='d-flex justify-content-end'>{formatCurrency(item?.amount)}</div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end'>{formatCurrency(item?.paid)}</div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end'>{formatCurrency(item?.balance)}</div>
                                        </td>
                                        <td className='pe-3 py-0'>
                                            <div className='d-flex justify-content-end flex-shrink-0 pt-2'>
                                                <a onClick={() => downloadInvoicePdf(item?.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                                    <KTIcon iconName='folder-down' className='fs-3' />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {invoiceData?.length === 0 ?
                        <p className='d-flex justify-content-center'>There are no invoices to display</p>
                        : ''
                    }
                </div>
            </UILoader>
        </Fragment>
    )
}

export default CustomerInvoiceTab