import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Input, Label, Row, Spinner } from 'reactstrap'
import { EntityService } from '../../../modules/Services/Entity'
import toast from 'react-hot-toast';
import { useAuth } from '../../../modules/auth';
import { userInfo } from '../../../modules/auth/core/_requests';

function DefaultWorkingHours({ entityDetail }) {
  const entity_id = localStorage.getItem('entity_id');
  const [start_time, setStart_time] = useState(null);
  const [end_time, setEnd_time] = useState(null);
  const [loading, setLoading] = useState('')
  const { setCurrentUser, logout } = useAuth()

  useEffect(() => {
    if (entityDetail) getSettingData()
  }, [entityDetail])

  const getSettingData = () => {
    setStart_time(entityDetail?.start_time)
    setEnd_time(entityDetail?.end_time)
  }

  const saveDefaultTime = () => {
    setLoading('update')
    const data = {
      start_time: start_time,
      end_time: end_time
    }

    EntityService.updateEntityProfile(entity_id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)

        userInfo(localStorage.getItem('entity_id'))
          .then((res: any) => {
            setCurrentUser(res?.data)
            localStorage.setItem('auth_user', JSON.stringify(res?.data))
            localStorage.setItem('entity_id', '1')
          })
          .catch((err: any) => {
            toast.error(err?.response?.data?.message)
            logout()
          })
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoading(null))
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className='dropdown-header'>Default Working Hours</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="d-flex">
          <div className="me-5">
            <Label className='form-label' for='postCode'>Start</Label>
            <Input type="time" value={start_time} onChange={(e) => setStart_time(e.target.value)} style={{ width: '100px' }} />
          </div>
          <div>
            <Label className='form-label' for='postCode'>End</Label>
            <Input type="time" value={end_time} onChange={(e) => setEnd_time(e.target.value)} style={{ width: '100px' }} />
          </div>
        </div>
      </CardBody>
      <Row className='m-4'>
        <div className='d-flex justify-content-end'>
          <Button color='primary' type='button' className='btn-sm' disabled={loading === 'update'} onClick={saveDefaultTime}>
            {loading === 'update' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
          </Button>
        </div>
      </Row>
    </Card>
  )
}

export default DefaultWorkingHours