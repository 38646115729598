import React, { Fragment, useEffect, useState } from 'react'
import { Col, Card, Form, Input, Label, Row, Button, Spinner } from "reactstrap"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from 'react-router'
import toast from 'react-hot-toast'
import ConfirmModal from '../../modules/commonModel/ConfirmModal'
import { VehicleService } from '../../modules/Services/Vehicles'
import UILoader from '../../modules/commonModel/Ui-loader'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import { useAuth } from '../../modules/auth'

const AddVehicle = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      reg_no: '',
      vehicle_name: '',
      make: '',
      model: '',
      wheelchairs: '',
      seats: '',
      courier_seats: '',
      wc: '',
      is_not_display_on_planner: false
    }
  })
  const {id} = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [centeredModal, setCenteredModal] = useState(false)
  const [pending, setPending] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  const entity_id = localStorage.getItem('entity_id')

  function fetchFormData(data: any) {
    setLoader(true)
    const obj = {
      entity_id: entity_id,
      reg_no: data?.reg_no,
      vehicle_name: data?.vehicle_name,
      make: data?.make,
      model: data?.model,
      wheelchairs: data?.wheelchairs,
      courier_seats: data?.courier_seats,
      wc: data?.wc,
      seats: data?.seats,
      is_not_display_on_planner: +data?.is_not_display_on_planner
    }
    if (id) delete obj.entity_id

    VehicleService[id ? 'updateVehicle' : 'storeVehicle'](entity_id, obj, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        navigate('/vehicle')
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoader(false))
  }

  function editData() {
    setEditLoader(true)
    VehicleService.editVehicle(entity_id, id)
      .then((res: any) => {
        const data = res.data?.response
        const obj = {
          reg_no: data?.reg_no,
          vehicle_name: data?.vehicle_name,
          make: data?.make,
          model: data?.model,
          wheelchairs: data?.wheelchairs,
          courier_seats: data?.courier_seats,
          wc: data?.wc,
          seats: data?.seats,
          is_not_display_on_planner: data?.is_not_display_on_planner
        }
        reset(obj)
      })
      .catch((err: any) => {
        console.log('err 78', err?.response?.data?.message)
      })
      .finally(() => setEditLoader(false))
  }

  useEffect(() => {
    if (id) {
      editData()
    }
  }, [id])

  function forceDeleteVehicle() {
    setPending(true)
    VehicleService.deleteVehicle(entity_id, id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setCenteredModal(false)
        navigate("/vehicle")
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setPending(false))
  }

  return (
    <>
        <Fragment>
          <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteVehicle}
            loader={pending}
          />
          <Card className='p-10'>
            <UILoader blocking={editLoader} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={editLoader ? 'loader-blur' : ''}>
              <Form onSubmit={handleSubmit(fetchFormData)}>
                <Row>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='reg_no'>Registration ID <span style={{ color: "red" }}> *</span></Label>
                    <Controller 
                      name='reg_no' 
                      control={control} 
                      rules={{required: true}} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Registration ID' 
                          invalid={ errors.reg_no && true } 
                          {...field} 
                        />
                      } 
                    />
                    {errors.reg_no && (<small className="text-danger ml-2 ">Registration ID is a required field</small>)}
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='vehicle_name'>Name</Label>
                    <Controller 
                      name='vehicle_name' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Name' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='make'>Make</Label>
                    <Controller 
                      name='make' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Make' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='model'>Model</Label>
                    <Controller 
                      name='model' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Model' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='seats'>Number of Seats</Label>
                    <Controller 
                      name='seats' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          type='number'
                          min={0}
                          placeholder='Enter Number of seats' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='courier_seats'>Courier Seats</Label>
                    <Controller 
                      name='courier_seats' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Courier Seats' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='courier_seats'>WC</Label>
                    <Controller 
                      name='wc' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter WC' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '6' className='mb-6'>
                    <Label className='form-label' for='wheelchairs'>Wheelchairs</Label>
                    <Controller 
                      name='wheelchairs' 
                      control={control} 
                      defaultValue=''
                      render={({ field }) => 
                        <Input 
                          placeholder='Enter Wheelchairs' 
                          {...field} 
                        />
                      } 
                    />
                  </Col>
                  <Col md= '12' className='mb-10'>
                    <Controller 
                      name='is_not_display_on_planner'
                      control={control}
                      render={({field: { onChange, value }}) => 
                        <Input 
                          type='checkbox' 
                          className='me-5' 
                          checked={value} 
                          onChange={onChange} 
                        />
                      }
                    />
                    <Label className='form-label'>Do not display on Planner</Label>
                  </Col>
                </Row>
                <div className='d-flex justify-content-between'>
                  <div>
                    { currentUser?.level == 1 && id &&
                      <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                        Delete
                      </Button>
                    }
                  </div>
                  <div>
                    <Button type="button" color="secondary" onClick={() => navigate("/vehicle")} style={{ marginRight: "10px" }}>
                      Close
                    </Button>
                    <Button type='submit' color='primary' disabled={loader} className='btn-next'>
                      { loader ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                    </Button>
                  </div>
                </div>
              </Form>
            </UILoader>
          </Card>
        </Fragment>
    </>
  )
}

export {AddVehicle}