import React, { Fragment, useEffect, useState } from 'react'
import DomainConfiguration from './Domain/DomainConfiguration';
import EntityProfile from './CompanyProfile/EntityProfile';
import DropDownConfig from './DropDown';
import FinancialConfig from './Financial';
import { EntityService } from '../../modules/Services/Entity';
import EmailTemplates from './TemplatesConfig/EmailTemplates';

function SettingPage() {
    const entity_id = localStorage.getItem('entity_id');
    const [currentTab, setCurrentTab] = useState(0);
    const [entityDetail, setEntityDetail] = useState(null)
    const [loader, setLoader] = useState('')

    function editData() {
        setLoader('edit')
        EntityService.editEntityProfile(entity_id)
            .then((res: any) => {
                setEntityDetail(res.data?.response)
            })
            .catch((err: any) => {
                console.log('err 66', err?.response?.data?.message)
            })
            .finally(() => setLoader(''))
    }

    useEffect(() => {
        editData()
    }, [])

    return (
        <Fragment>
            <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                        <a
                            className={`nav-link text-active-primary me-6 ` + (currentTab == 0 && 'active')}
                            onClick={() => setCurrentTab(0)}
                        >
                            Company Profile
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            className={`nav-link text-active-primary ms-0 me-6 ` + (currentTab == 1 && 'active')}
                            onClick={() => setCurrentTab(1)}
                        >
                            Financial
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            className={`nav-link text-active-primary ms-0 me-6 ` + (currentTab == 2 && 'active')}
                            onClick={() => setCurrentTab(2)}
                        >
                            System Configuration
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            className={`nav-link text-active-primary ms-0 me-6 ` + (currentTab == 3 && 'active')}
                            onClick={() => setCurrentTab(3)}
                        >
                            Domain
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            className={`nav-link text-active-primary ms-0 me-6 ` + (currentTab == 4 && 'active')}
                            onClick={() => setCurrentTab(4)}
                        >
                            Templates
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <div style={{ display: currentTab === 0 ? 'block' : 'none' }}>
                    <EntityProfile entityDetail={entityDetail} loader={loader} setLoader={setLoader} />
                </div>
                <div style={{ display: currentTab === 1 ? 'block' : 'none' }}>
                    <FinancialConfig entityDetail={entityDetail} />
                </div>
                <div style={{ display: currentTab === 2 ? 'block' : 'none' }}>
                    <DropDownConfig entityDetail={entityDetail} />
                </div>
                <div style={{ display: currentTab === 3 ? 'block' : 'none' }}>
                    <DomainConfiguration />
                </div>
                <div style={{ display: currentTab === 4 ? 'block' : 'none' }}>
                    <EmailTemplates />
                </div>
            </div>
        </Fragment>
    )
}

export default SettingPage