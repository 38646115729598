
import { Button, Col, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import Flatpickr from 'react-flatpickr'

function EditLineItem({ openModifyLine, setOpenModifyLine, loader, setModifyLineData, currentInvoiceMethod }) {

    const handleData = (field: any, value: any) => {
        setOpenModifyLine((pre: any) => ({...pre, data: {...pre.data, [field]: value }}))
    }

    const onSubmit = () => {
        setModifyLineData(openModifyLine?.data)
        setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null })
    }

    return (
        <Modal isOpen={openModifyLine?.open} className="modal-dialog-centered modal-lg" toggle={() => setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null })} >
            <ModalBody className="px-10">
                <div>
                    <Row className='mb-10'>
                        <Col md='12' className='d-flex align-items-center justify-content-center'>
                            <h3 className='mb-0' style={{ color: '#6C757D' }}>{openModifyLine?.data?.edit_custom === 'add' ? 'Add Line Item' : 'Edit Line Item'}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Label className='d-flex align-items-center me-3'>Date</Label>
                        <Col md='3'>
                            <Flatpickr
                                className='form-control invoice_date'
                                placeholder='Select Date'
                                value={openModifyLine?.data?.date}
                                options={{
                                    enableTime: false,
                                    altInput: true, 
                                    dateFormat: 'Y-m-d H:i',
                                    disableMobile : true
                                }}
                                onChange={(value) => handleData('date', value[0])}
                            />
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Label className='d-flex align-items-center me-3'>Description</Label>
                        <Col md='12'>
                            <Input
                                type='textarea'
                                placeholder='Enter description'
                                rows='5'
                                value={openModifyLine?.data?.description}
                                onChange={(e) => handleData('description', e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Col md='4'>
                            <Label className='d-flex align-items-center me-3'>Rate</Label>
                            <Input
                                type='number'
                                placeholder='Enter rate'
                                disabled={currentInvoiceMethod?.value == 'display_line_per_job' && openModifyLine?.data?.item?.price_by_job}
                                value={openModifyLine?.data?.rate}
                                onChange={(e) => handleData('rate', e.target.value)}
                            />
                        </Col>
                        <Col md='4'>
                            <Label className='d-flex align-items-center me-3'>Quantity</Label>
                            <Input
                                type='number'
                                placeholder='Enter quantity'
                                disabled={currentInvoiceMethod?.value == 'display_line_per_job' && openModifyLine?.data?.item?.price_by_job}
                                value={openModifyLine?.data?.qty}
                                onChange={(e) => handleData('qty', e.target.value)}
                            />
                        </Col>
                        <Col md='4'>
                            <Label className='d-flex align-items-center me-3'>Total</Label>
                            <Input
                                type='number'
                                placeholder='Enter total'
                                disabled={true}
                                value={openModifyLine?.data?.rate * openModifyLine?.data?.qty}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-10'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" className='btn btn-sm me-2' color="secondary" onClick={() => setOpenModifyLine({ open: false, id: openModifyLine?.id, data: null })}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={loader === 'modify_line'} color='primary' className='btn btn-sm' onClick={onSubmit}>
                                {loader === 'modify_line' ? <Spinner size='sm' /> : 'Save'}
                            </Button>
                        </div>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EditLineItem