import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import toast from 'react-hot-toast'
import ReactQuill from 'react-quill'
import { Card, CardBody, CardFooter, CardTitle, Col, Row, Spinner } from 'reactstrap'
import { SettingsService } from '../../../modules/Services/Settings'
import CopyClipboard from '../../../modules/commonModel/CopyClipboard'

function EmailTemplates() {
    const entity_id = localStorage.getItem('entity_id')
    const [template, setTemplate] = useState(null)
    const [loader, setLoader] = useState('')

    const handleTemplate = (field: string, value: any) => {
        setTemplate((pre: any) => ({ ...pre, [field]: value }))
    }

    useEffect(() => {
        SettingsService.getEmailTemplate(entity_id)
            .then((res: any) => {
                setTemplate(res?.data?.response)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
    }, [])

    const saveTemplate = () => {
        setLoader('save')
        const data = {
            booking_confirmation: template?.booking_confirmation,
            invoice_issue: template?.invoice_issue
        }
        SettingsService.updateEmailTemplate(entity_id, data)
            .then((res: any) => {
                toast.success(res?.data?.message)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(''))
    }

    return (
        <Fragment>
            <Card className='mb-5'>
                <CardBody>
                    <Row>
                        <Col md='6'>
                            <CardTitle className='dropdown-header mb-3'>Booking Confirmation</CardTitle>
                            <ReactQuill
                                theme="snow"
                                value={template?.booking_confirmation}
                                onChange={(value) => handleTemplate('booking_confirmation', value)}
                                modules={{
                                    toolbar: {
                                        container: [
                                            [{ header: [1, 2, 3, 4, false] }],
                                            ["bold", "italic", "underline"],
                                            [{ list: "ordered" }, { list: "bullet" }],
                                            ["link", "image"],
                                            ["blockquote"]
                                        ]
                                    },
                                }}
                                formats={[
                                    "header",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "list",
                                    "bullet",
                                    "link",
                                    "image"
                                ]}
                            />
                            <Row className='mt-5'>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{client_name}}`}
                                        id="client-name"
                                    />
                                </Col>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{booking_number}}`}
                                        id="booking-number"
                                    />
                                </Col>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{booking_detail}}`}
                                        id="booking-number"
                                    />
                                </Col>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{notes}}`}
                                        id="notes"
                                    />
                                </Col>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{rate}}`}
                                        id="rate"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md='6'>
                            <CardTitle className='dropdown-header mb-3'>Invoice Issue</CardTitle>
                            <ReactQuill
                                theme="snow"
                                value={template?.invoice_issue}
                                onChange={(value) => handleTemplate('invoice_issue', value)}
                                modules={{
                                    toolbar: {
                                        container: [
                                            [{ header: [1, 2, 3, 4, false] }],  
                                            ["bold", "italic", "underline"],
                                            [{ list: "ordered" }, { list: "bullet" }],
                                            ["link", "image"],
                                            ["blockquote"]
                                        ]
                                    },
                                }}
                                formats={[
                                    "header",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "list",
                                    "bullet",
                                    "link",
                                    "image"
                                ]}
                            />
                            <Row className='mt-5'>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{client_name}}`}
                                        id="client-name"
                                    />
                                </Col>
                                <Col lg='6' xl='3'>
                                    <CopyClipboard
                                        text={`{{payment_link}}`}
                                        id="payment-link"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <div className='d-flex justify-content-end'>
                        <Button type='button' size='sm' color='primary' disabled={loader === 'save'} onClick={saveTemplate}>
                            {loader === 'save' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
                        </Button>
                    </div>
                </CardFooter>
            </Card >
        </Fragment >
    )
}

export default EmailTemplates