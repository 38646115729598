import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'reactstrap'
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async'
import toast from 'react-hot-toast';
import { CustomerService } from '../../modules/Services/Customers';
import moment from 'moment';
import { InvoiceService } from '../../modules/Services/Invoice';
import ConfirmModal from '../../modules/commonModel/ConfirmModal';
import { Icon } from '@iconify/react';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';
import UILoader from '../../modules/commonModel/Ui-loader';
import { customSelectStyles, formatCurrency, selectThemeColors } from '../../modules/commonModel/Utils';
import ReactSelect from 'react-select'
import InvoicePayment from './InvoicePayment';
import { useAuth } from '../../modules/auth';
import IssueInvoice from './IssueInvoice';
import EditLineItem from './EditLineItem';

function InvoiceModel({
    openInvoice = false,
    setOpenInvoice,
    invoiceId = null,
    setInvoiceId = (null),
    setRenderElementService,
    bookingData
}) {
    const { control, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({ });
    const { currentUser } = useAuth()
    const entity_id = localStorage.getItem('entity_id')
    const [currentCustomer, setCurrentCustomer] = useState({ value: null, label: 'Select Customer', customerEmail: null })
    const [invoiceDate, setInvoiceDate] = useState(new Date())
    const [due_date, setDue_date] = useState(new Date())
    const [loader, setLoader] = useState(null)
    const [invoiceData, setInvoiceData] = useState(null)
    const [invoiceItem, setInvoiceItem] = useState([])
    const [centeredModal, setCenteredModal] = useState(false)
    const [centeredIssueModal, setCenteredIssueModal] = useState(false)
    const [issueModal, setIssueModal] = useState(false)
    const [invoice_note, setInvoice_note] = useState('')
    const [currentStatus, setCurrentStatus] = useState({ value: null, label: 'Select Status' })
    const [paymentModal, setPaymentModal] = useState({ isOpen: false, payment_id: null, type: '' })
    const [render, setRender] = useState(false)
    const [purchaseOrder, setPurchaseOrder] = useState('')
    const [newCustomerAddress, setNewCustomerAddress] = useState(null)
    const [openModifyLine, setOpenModifyLine] = useState({ open: false, id: null, data: null })
    const [modifyLineData, setModifyLineData] = useState(null)
    const [flag, setFlag] = useState(false)
    const [lineItemModal, setLineItemModal] = useState(false)
    const [lineItem_text, setLineItem_text] = useState('')
    const [lineItem_amount, setLineItem_amount] = useState(null)
    const [emailClientOption, setEmailClientOption] = useState([])
    const [currentClientEmail, setCurrentClientEmail] = useState({ value: null, label: 'Select Client', email: '', name: '', is_contact: null })
    const [isInvoiceEmail, setIsInvoiceEmail] = useState(true)
    const [currentInvoiceMethod, setCurrentInvoiceMethod] = useState({ value: 'display_line_per_job', label: 'Display Line Per Job' })
    const [invoicePayment, setInvoicePayment] = useState([])
    let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const invoiceMethodDrop = [
        { value: 'display_line_per_job', label: 'Display Line Per Job' },
        { value: 'display_line_per_booking', label: 'Display One Line Per Booking' }
    ]

    function getInvoiceData() {
        setLoader('editInvoice')
        InvoiceService.editInvoice(entity_id, invoiceId)
        .then((res: any) => {
            setInvoiceData(res?.data?.response)
            setCurrentCustomer({ value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name, customerEmail: res?.data?.response?.customer?.customer_email })
            setInvoiceItem(res?.data?.response?.invoice_items)
            setInvoice_note(res?.data?.response?.invoice_note || '')
            setCurrentStatus({ value: res?.data?.response?.status?.id, label: res?.data?.response?.status?.label })
            setPurchaseOrder(res?.data?.response?.invoice_purchase_order)
            setCurrentInvoiceMethod({ value: res?.data?.response?.display_invoice_format, label: res?.data?.response?.display_invoice_format === 'display_line_per_job' ? 'Display Line Per Job' : 'Display One Line Per Booking' })
            
            const date: any = res?.data?.response?.date ? moment(res?.data?.response?.date).format('YYYY-MM-DD') : null
            const dueDate: any = res?.data?.response?.due_date ? moment(res?.data?.response?.due_date).format('YYYY-MM-DD') : null
            setInvoiceDate(date)
            setDue_date(dueDate)
            reset({
                bill_to_name: { value: res?.data?.response?.customer_id, label: res?.data?.response?.customer_name },
                bill_to_email: res?.data?.response?.customer?.customer_email
            })
            setFlag(!flag)

            const invoice_date = document.getElementsByClassName('invoice_date')[1] as HTMLInputElement;
            if (invoice_date && res?.data?.response?.status?.id != 1) invoice_date.disabled = true;
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
        .finally(() => setLoader(null))
    }

    const getClientEmailList = () => {
        const data = getValues()
        const option = [];
        option.push({ value: data?.bill_to_name?.value, label: data?.bill_to_email + ' (Client Email)', email: data?.bill_to_email, name: data?.bill_to_name?.label, is_contact: 0 })

        CustomerService.getCustomeContactsList(entity_id, currentCustomer?.value, {})
        .then((res: any) => {

            res.data?.response?.map((item: any) => {
                option.push({ value: item?.id, label: item?.contact_email + ' (' + item?.contact_role + ')', email: item?.contact_email, name: item?.contact_name, is_contact: 1 })
            })
        })
        .catch((err: any) => console.log(err?.response?.data?.message))
        setEmailClientOption(option)
    }

    useEffect(() => {
        if (openInvoice) {
            if (invoiceId) {
              getInvoiceData()
            } else {
                bookingData?.length ? bookingData : [bookingData]?.map((item: any) => {
                    item?.coachiejoblegs?.map((data: any) => {
                        data.description = data?.pick_up_location + (data?.pick_up_location && data?.drop_off_location && ' - ') + data?.drop_off_location + (data?.pax ? (' ' + data?.pax + 'pax') : '') + (data?.job_reference ? (', ' + data?.job_reference) : '');
                        data.job_reference = data?.job_reference;
                        return data;
                    })
                    return item;
                })
                setInvoiceItem(bookingData ? (bookingData?.length ? bookingData : [bookingData]) : [])
                setCurrentCustomer({ value: bookingData?.length ? bookingData[0]?.bill_to_customer_id : bookingData?.bill_to_customer_id, label: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name, customerEmail: bookingData?.length ? bookingData[0]?.bill_to_customer_email : bookingData?.bill_to_customer_email })
                setPurchaseOrder((bookingData?.length ? bookingData[0]?.purchase_order : bookingData?.purchase_order) || '')
                reset({
                    bill_to_name: { value: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name, label: bookingData?.length ? bookingData[0]?.bill_to_customer_name : bookingData?.bill_to_customer_name },
                    bill_to_email: bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_email : bookingData?.bill_to_customer?.customer_email
                })
                setCurrentStatus({ value: 1, label: 'Draft' });
                const payment = []; 
                (bookingData?.length ? bookingData : [bookingData])?.map((item) => {
                    payment.push(...item?.booking_deposit)
                })
                setInvoicePayment(payment)
            }
        } else {
            reset({})
            setCurrentCustomer({ value: null, label: 'Select Customer', customerEmail: null })
            setCurrentStatus({ value: null, label: 'Select Status' })
            setInvoice_note('')
            setNewCustomerAddress(null)
            setCurrentInvoiceMethod({ value: 'display_line_per_job', label: 'Display Line Per Job' })
            setInvoiceItem([])
        }
    }, [openInvoice, invoiceId, render, bookingData])

    useEffect(() => {
        if (currentCustomer?.value) getClientEmailList()
    }, [currentCustomer])

    useEffect(() => {
        if (!centeredIssueModal) setCurrentClientEmail({ value: null, label: 'Select Client', email: '', name: '', is_contact: null })
    }, [centeredIssueModal])
    
    const loadOptions = (inputValue: any, callback: any) => {
        if (customerTimeoutId) {
            clearTimeout(customerTimeoutId);
        }
        customerTimeoutId = setTimeout(() => {
            const obj = { 
                getall: 1,
                fields: 'id,customer_name,customer_email',
                customer_name : inputValue
            }
            CustomerService.getCustomerList(entity_id, obj)
            .then((res: any) => {
                callback(res?.data?.response?.map((item: any) => {
                    return { value: item?.id, label: item?.customer_name, customerEmail: item?.customer_email}
                }))
            })
            .catch(err => console.log(err?.response?.data?.message))
        }, 1000)
    }

    const onSubmit = () => {
        setLoader('submit')
        const jobData = invoiceItem?.map((item: any) => {
            const job_items = item?.coachiejoblegs?.map((data: any) => {
                return {
                    id: data?.id,
                    start_at_date: data?.start_at_date,
                    description: data?.description,
                    cost: data?.cost,
                    qty: data?.qty || 1,
                    job_reference: data?.job_reference
                }
            })
            return {
                id: invoiceId ? item?.id : null,
                item_names: item?.coachiejoblegs?.map((data: any) => { return data?.id }),
                job_description: job_items?.length ? job_items : null,
                booking_id: item?.booking_id || item?.id,
                price: item?.price,
                jobs_start_at: item?.jobs_start_at ? (typeof item?.jobs_start_at === 'string' ? item?.jobs_start_at : moment(item?.jobs_start_at).format('YYYY-MM-DD')) : null,
                item_text: item?.item_text,
                qty: item?.qty
            }
        })

        const payload: any = {
            entity_id: entity_id,
            customer_id: currentCustomer?.value,
            date: invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : null,
            due_date: due_date ? moment(due_date).format('YYYY-MM-DD') : null,
            amount: getVisibleSum() - getPaymentSum(),
            charge: getVisibleSum(),
            invoice_items: jobData,
            invoice_note: invoice_note,
            invoice_purchase_order: purchaseOrder,
            display_invoice_format: currentInvoiceMethod?.value
        }
        if (invoiceId) { 
            payload.status = currentStatus?.value
        }

        InvoiceService[invoiceId ? 'updateInvoice' : 'storeInvoice'](entity_id, payload, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setOpenInvoice(false)
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }
    
    useEffect(() => {
        if (!lineItemModal) {
            setLineItem_text('')
            setLineItem_amount(null)
        }
    }, [lineItemModal])

    function forceDeleteInvoice() {
        setLoader('delete')
        InvoiceService.deleteInvoice(entity_id, invoiceId)
        .then((res: any) => {
            toast.success(res?.data?.message)
            setCenteredModal(false)
            setOpenInvoice(false)
            setRenderElementService(1)
            setInvoiceId(null)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    function getVisibleSum() {
        return invoiceItem?.reduce((total, item) => {
            // Custom line items (always visible)
            if (item.custom_line_item) {
                return total + (item.price || 0) * (item.qty || 1);
            }

            // Handle different display modes
            if (currentInvoiceMethod?.value === 'display_line_per_job') {
                // When showing job legs - only sum legs if NOT using price_by_job_legs
                if (item.coachiejoblegs?.length && item.price_by_job_legs) {
                    const legsSum = item.coachiejoblegs.reduce((legTotal: number, leg: any) => {
                        return legTotal + (leg.cost || 0) * (leg.qty || 1);
                    }, 0);
                    return total + legsSum;
                }

                // When using price_by_job_legs, parent price already includes legs sum
                if (!item.price_by_job_legs) {
                    return total + (item.price || 0) * (item.qty || 1);
                }
            } else {
                // When showing per booking - sum parent items
                if (item.price_by_job || item.price_by_job_legs) {
                    return total + (item.price || 0) * (item.qty || 1);
                }
            }
            return total;
        }, 0);
    }

    function getBookingSum() {
        const sumWithInitial = invoiceItem?.reduce((accumulator, currentValue) => accumulator + (currentValue?.price * (currentValue?.qty || 1)), 0)
        // const sumWithInitial = invoiceItem?.reduce((accumulator, currentValue) => accumulator + (currentValue?.price_by_job === 1 ? (currentValue?.job_price * (currentValue?.qty || 1)) : (currentValue?.price_by_job_legs === 1 ? (currentValue?.job_leg_sum * (currentValue?.qty || 1)) : (currentValue?.custom_line_item ? (currentValue?.price * (currentValue?.qty || 1)) : 0))), 0)
        return sumWithInitial
    }

    function getPaymentSum() {
        const sumWithInitial = (invoiceId ? invoiceData?.payments : invoicePayment)?.reduce((accumulator, currentValue) => accumulator + currentValue?.amount, 0)
        return sumWithInitial
    }

    const stripePayment = () => {
        setLoader('stripe_payment')
        InvoiceService.openStripePayment(entity_id, invoiceId)
        .then((res: any) => {
            window.location.href = res?.data?.response?.payment_link;
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const issuePayment = () => {
        setLoader('issue')
        const data = {
            isInvoiceEmail: isInvoiceEmail,
            is_contact: currentClientEmail?.is_contact,
            name: isInvoiceEmail ? currentClientEmail?.name : null,
            email: isInvoiceEmail ? currentClientEmail?.email : null,
            invoice_number: isInvoiceEmail ? invoiceData?.invoice_number : null
        }
        InvoiceService.issuePayment(entity_id, invoiceId, data)
        .then((res: any) => {
            toast.success(res?.data?.message)
            getInvoiceData()
            setRenderElementService(1)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => { setCenteredIssueModal(false); setLoader(null) })
    }

    const getCustomerAddress = (data: any) => {
        CustomerService.editCustomer(entity_id, data?.value)
        .then((res: any) => {
            setNewCustomerAddress(res?.data?.response)
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    const jobsText = (item: any) => {
        const jobsData = item?.map((data: any) => {    
            return (data?.job_number + (data?.start_at_date ? ' ' : '') + (data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : '') + (data?.pick_up_location ? ' ' : '') + (data?.pick_up_location || '') + (data?.drop_off_location ? ' ' : '') + (data?.pick_up_location && data?.drop_off_location ? ' - ' : '') + (data?.drop_off_location ? ' ' : '') + (data?.drop_off_location || '') + (data?.pax ? ' ' : '') + (data?.pax ? (data?.pax + 'pax') : '') + (data?.job_reference ? (', ' + data?.job_reference) : ''))
        })
        return jobsData
    }
    
    useEffect(() => {
        if (modifyLineData && !openModifyLine?.open) {
            if (modifyLineData?.edit_custom == 'add') {
                invoiceItem.push({ 
                    jobs_start_at: modifyLineData?.date,
                    item_text: modifyLineData?.description,
                    price: modifyLineData?.rate,
                    qty: modifyLineData?.qty || 1,
                    custom_line_item: 1,
                    custom_id: Math.floor(1000 + Math.random() * 9000)
                })
            } else if (typeof modifyLineData?.edit_custom === 'number') {
                invoiceItem?.map((item) => {
                    if (item?.custom_id === modifyLineData?.edit_custom) {
                        item.jobs_start_at = modifyLineData?.date;
                        item.item_text = modifyLineData?.description;
                        item.price = modifyLineData?.rate;
                        item.qty = modifyLineData?.qty || 1
                    }
                })
            } else if (modifyLineData?.field === 'job') {
                invoiceItem?.map((item) => {
                    item?.coachiejoblegs?.map((data: any) => {
                        if (data?.id === openModifyLine?.id) {
                            data.start_at_date = modifyLineData?.date;
                            data.description = modifyLineData?.description;
                            data.cost = modifyLineData?.rate;
                            data.qty = modifyLineData?.qty || 1;
                        }
                    })
                })
            } else {
                invoiceItem?.map((item) => {
                    if (item?.id === openModifyLine?.id) {
                        item.jobs_start_at = modifyLineData?.date;
                        item.item_text = modifyLineData?.description;
                        item.price = modifyLineData?.rate;
                        item.qty = modifyLineData?.qty || 1;
                    }
                })
            }
            setFlag(!flag)
        }
    }, [modifyLineData])

    const addLineItem = () => {
        const data = {
            invoice_text: lineItem_text,
            price: lineItem_amount,
            local_price: lineItem_amount,
            custom_line_item: 1
        }
        setInvoiceItem((pre) => [...pre, data])
        setLineItemModal(false)
    }

    function downloadInvoicePdf(invoice_id: any) {
        setLoader('pdf')
        InvoiceService.downloadInvoicePdf(entity_id, invoice_id)
        .then((res: any) => { 
            toast.success(res?.data?.message)
            window.open(res?.data?.url, '_blank', 'noopener,noreferrer') 
        })
        .catch((err: any) => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => setLoader(null))
    }

    return (
    <>
        <Modal isOpen={openInvoice} className="modal-dialog-centered modal-xl" toggle={() => setOpenInvoice(false)} >
            <UILoader blocking={loader === 'editInvoice'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'editInvoice' ? 'loader-blur' : ''}>
                <ModalBody className="px-sm-5">
                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className='px-5'>
                                <Col md='12' className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <span className='me-5' style={{fontSize: '28px'}}>{invoiceData?.invoice_number ? 'Invoice' : 'Create Invoice'}</span>
                                        <span style={{fontSize: '28px'}}>{invoiceData?.invoice_number}</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize: '28px'}}>{currentStatus?.value ? currentStatus?.label : ''}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-10 mb-5 px-5'>
                                <Col md='6'>
                                    <div>
                                        <Label className='form-label' for='bill_to_name'>Bill To</Label>
                                        <Controller name='bill_to_name' control={control} rules={{ required: true }}
                                            render= {({ field: { onChange } }) => (
                                                <AsyncSelect
                                                    isClearable={true}
                                                    className='react-select w-100'
                                                    classNamePrefix='select'
                                                    placeholder='Search Customer'
                                                    isRtl={false}
                                                    name='callback-react-select'
                                                    styles={customSelectStyles(errors?.bill_to_name)}
                                                    theme={selectThemeColors}
                                                    loadOptions={loadOptions}
                                                    value={currentCustomer}
                                                    isDisabled={currentStatus?.value != 1}
                                                    cacheOptions
                                                    onChange={(data: any) => {
                                                        onChange(data)
                                                        setCurrentCustomer(data)
                                                        if (data?.value) getCustomerAddress(data)
                                                        else setNewCustomerAddress({})
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.bill_to_name && (<small className="text-danger ml-2 ">Name is a required field</small>)}
                                    </div>
                                    <div className='row my-3'>
                                        <div className='col-6'>
                                            <Label className='d-flex align-items-center me-3'>Invoice Date</Label>
                                            <Flatpickr
                                                className='form-control invoice_date'
                                                placeholder='Select Date'
                                                value={invoiceDate}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true, 
                                                    dateFormat: 'Y-m-d H:i',
                                                    disableMobile : true
                                                }}
                                                onChange={(value) => {
                                                    setInvoiceDate(value[0])
                                                }}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <Label className='d-flex align-items-center me-3'>Due Date</Label>
                                            <Flatpickr
                                                className='form-control invoice_date'
                                                placeholder='Select Date'
                                                value={due_date}
                                                options={{
                                                    enableTime: false,
                                                    altInput: true, 
                                                    dateFormat: 'Y-m-d H:i',
                                                    disableMobile : true
                                                }}
                                                onChange={(value) => {
                                                    setDue_date(value[0])
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Label className='d-flex align-items-center me-3'>Purchase Order</Label>
                                            <Input type='text' 
                                                placeholder='Enter Purchase Order' 
                                                disabled={invoiceId}
                                                value={purchaseOrder} 
                                                onChange={(e) => setPurchaseOrder(e.target.value)} 
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md='6' className='mt-5'>
                                    <div className='text-end'>
                                        {newCustomerAddress ?
                                            <>
                                                <p className='mb-0'>{newCustomerAddress?.customer_address_1}</p>
                                                <p className='mb-0'>{newCustomerAddress?.customer_address_2}</p>
                                                <p className='mb-0'>{newCustomerAddress?.customer_city }</p>
                                                <p className='mb-0'>{newCustomerAddress?.customer_county_name}</p>
                                                <p className='mb-0'>{newCustomerAddress?.customer_country_name}</p>
                                                <p className='mb-0'>{newCustomerAddress?.customer_postcode}</p>
                                            </>
                                        :
                                            (invoiceData?.customer?.customer_full_address || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_full_address : bookingData?.bill_to_customer?.customer_full_address)) ? 
                                                (invoiceData?.customer?.customer_full_address || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_full_address : bookingData?.bill_to_customer?.customer_full_address))?.split(',')?.map((item: any, i: any) => {
                                                    return <p className='mb-0' key={i}>{item}</p>
                                                })
                                                : 
                                                <>
                                                    <p className='mb-0'>{invoiceData?.customer?.customer_address_1 || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_address_1 : bookingData?.bill_to_customer?.customer_address_1)}</p>
                                                    <p className='mb-0'>{invoiceData?.customer?.customer_address_2 || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_address_2 : bookingData?.bill_to_customer?.customer_address_2)}</p>
                                                    <p className='mb-0'>{invoiceData?.customer?.customer_city || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_city : bookingData?.bill_to_customer?.customer_city)}</p>
                                                    <p className='mb-0'>{invoiceData?.customer?.county_name || (bookingData?.length ? bookingData[0]?.bill_to_customer?.county_name : bookingData?.bill_to_customer?.county_name)}</p>
                                                    <p className='mb-0'>{invoiceData?.customer?.countries_name || (bookingData?.length ? bookingData[0]?.bill_to_customer?.countries_name : bookingData?.bill_to_customer?.countries_name)}</p>
                                                    <p className='mb-0'>{invoiceData?.customer?.customer_postcode || (bookingData?.length ? bookingData[0]?.bill_to_customer?.customer_postcode : bookingData?.bill_to_customer?.customer_postcode)}</p>
                                                </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='12' className='d-flex justify-content-end'>
                                    <div className='px-3 w-250'>
                                        <ReactSelect
                                            isClearable={false}
                                            className='react-select '
                                            classNamePrefix='select'
                                            placeholder='Select Status'
                                            styles={customSelectStyles(null)}
                                            theme={selectThemeColors}
                                            options={invoiceMethodDrop}
                                            value={currentInvoiceMethod}
                                            onChange={(data: any) => {
                                                setCurrentInvoiceMethod(data)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='px-5'>
                                <div className='job_table mt-3' style={{ maxHeight: '400px', minHeight: '150px', overflowY: 'scroll' }}>
                                    <Table className='w-100 job_table' bordered>
                                        <thead>
                                            <tr>
                                                <th style={{width: '11%'}}>BOOKING REF</th>
                                                <th style={{width: '9%'}}>DATE</th>
                                                <th>DESCRIPTION</th>
                                                <th style={{width: '13%'}}>
                                                    <div className='d-flex justify-content-end'>RATE</div>
                                                </th>
                                                <th style={{width: '10%'}}>
                                                    <div className='d-flex justify-content-end'>QTY</div>
                                                </th>
                                                <th style={{width: '13%'}}>
                                                    <div className='d-flex justify-content-end pe-3'>TOTAL</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { invoiceItem?.map((item: any) => {    
                                                return (
                                                    <>
                                                        {(item?.price_by_job_legs && currentInvoiceMethod?.value == 'display_line_per_job') ? '' :
                                                            <tr>
                                                                <td>
                                                                    <div onClick={() => (currentStatus?.value == 1 ? setOpenModifyLine({ open: true, id: item?.id, data: { date: item?.jobs_start_at, description: item?.item_text, rate: item?.price, qty: item?.qty || 1 }}) : '')}
                                                                    >
                                                                        <h6 className={` ${currentStatus?.value == 1 ? 'cursor-pointer' : ''} m-0`}>{item?.booking_number}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className={item?.custom_line_item ? 'cursor-pointer' : ''} onClick={() => (item?.custom_line_item ? (currentStatus?.value == 1 ? setOpenModifyLine({ open: true, id: item?.id, data: { date: item?.jobs_start_at, description: item?.item_text, rate: item?.price, qty: item?.qty || 1, edit_custom: item?.custom_id }}) : '') : '')}> 
                                                                        {item?.jobs_start_at ? moment(item?.jobs_start_at).format('DD/MM/YYYY') : ''}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item?.item_text}
                                                                </td>
                                                                <td className='pb-0'>
                                                                    <div className='d-flex justify-content-end'>
                                                                        <>
                                                                            &#8364; {formatCurrency(item?.price ? item?.price : 0)}
                                                                        </>
                                                                        {/* {item?.custom_line_item ?
                                                                            <>
                                                                                &#8364; {formatCurrency(item?.price ? item?.price : 0)}
                                                                            </>
                                                                            : 
                                                                            <>
                                                                                &#8364; {formatCurrency(item?.price_by_job === 1 ? item?.job_price : item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0)}
                                                                            </>
                                                                        } */}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-end'>
                                                                        {item?.qty || 1}
                                                                    </div>
                                                                </td>
                                                                <td className='pe-5 pb-0'>
                                                                    <div className='d-flex justify-content-end'>
                                                                        <>
                                                                            &#8364; {formatCurrency((item?.price ? item?.price : 0) * (item?.qty || 1))}
                                                                        </>
                                                                        {/* {item?.custom_line_item ?
                                                                            <>
                                                                                &#8364; {formatCurrency((item?.price ? item?.price : 0) * (item?.qty || 1))}
                                                                            </>
                                                                            : 
                                                                            <>
                                                                                &#8364; {formatCurrency((item?.price_by_job === 1 ? item?.job_price : (item?.price_by_job_legs === 1 ? item?.job_leg_sum : 0)) * (item?.qty || 1))}
                                                                            </>
                                                                        } */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {currentInvoiceMethod?.value == 'display_line_per_job' ?
                                                            // item?.item_text ?
                                                            //     <tr>
                                                            //         <td></td>
                                                            //         <td>
                                                            //             {item?.coachiejoblegs?.map((data: any) => {
                                                            //                 return (
                                                            //                     <div>{data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : ''}</div>
                                                            //                 )
                                                            //             })}
                                                            //         </td>
                                                            //         <td>
                                                            //             {(item?.item_text ? (typeof item?.item_text === 'string' ? item?.item_text.split('\n') : item?.item_text) : [])?.map((line: any, index: any) => (
                                                            //                 <div key={index} className={currentStatus?.value == 1 ? 'cursor-pointer' : ''}>{line}</div>
                                                            //             ))}
                                                            //         </td>
                                                            //         <td></td>
                                                            //         <td></td>
                                                            //         <td></td>
                                                            //         <td></td>
                                                            //     </tr>
                                                            item?.coachiejoblegs?.map((data: any) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <div onClick={() => (currentStatus?.value == 1 ? 
                                                                                setOpenModifyLine({ open: true, id: data?.id, data: { date: data?.start_at_date, description: data?.description ? data?.description : (data?.pick_up_location + (data?.pick_up_location && data?.drop_off_location && ' - ') + data?.drop_off_location + (data?.pax ? (' ' + data?.pax + 'pax') : '') + (data?.job_reference ? (', ' + data?.job_reference) : '')), rate: data?.cost, qty: data?.qty || 1, field: 'job', item: item }}) 
                                                                                : '')}
                                                                            >
                                                                                <h6 className={` ${currentStatus?.value == 1 ? 'cursor-pointer' : ''} m-0`}>{item?.booking_number}</h6>
                                                                            </div>
                                                                        </td>
                                                                        <td>{data?.start_at_date ? moment(data?.start_at_date).format('DD/MM/YYYY') : ''}</td>
                                                                        <td>
                                                                            {data?.description ? data?.description : (data?.pick_up_location + (data?.pick_up_location && data?.drop_off_location && ' - ') + data?.drop_off_location + (data?.pax ? (' ' + data?.pax + 'pax') : '') + (data?.job_reference ? (', ' + data?.job_reference) : '') )}
                                                                        </td>
                                                                        <td>
                                                                            <div className='d-flex justify-content-end'>
                                                                                {item?.price_by_job_legs ? (data?.cost ? <span>{formatCurrency(data?.cost)}</span>  : 0) : '' }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className='d-flex justify-content-end'>
                                                                                {item?.price_by_job_legs ? (data?.qty ? data?.qty : 1) : '' }
                                                                            </div>
                                                                        </td>
                                                                        <td className='d-flex justify-content-end pe-5'>
                                                                            {item?.price_by_job_legs ? (data?.cost ? <span>{formatCurrency(data?.cost * (data?.qty || 1))}</span> : 0) : '' }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : ''
                                                        }
                                                    </>
                                                )
                                            })}
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <div className='mt-4'>
                                                        {invoiceItem[0]?.is_charge_vat ?
                                                            <p className='d-flex justify-content-end mb-1'>Subtotal</p> : ''
                                                        }
                                                        {invoiceItem[0]?.is_charge_vat ?
                                                            <p className='d-flex justify-content-end mb-2'>VAT {'(' + (invoiceItem[0]?.is_charge_vat ? (invoiceItem[0]?.vat_rate || 0) : 0) + '%)'}</p> : ''
                                                        }
                                                        <p className='d-flex justify-content-end mb-0'>Total </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='pe-3 mt-4'>
                                                        {invoiceItem[0]?.is_charge_vat ?
                                                            <p className='d-flex justify-content-end mb-1'>{formatCurrency(getVisibleSum())}</p> : ''
                                                        }
                                                        {invoiceItem[0]?.is_charge_vat ?
                                                            <p className='d-flex justify-content-end mb-2'>{formatCurrency((getVisibleSum() * (invoiceItem[0]?.is_charge_vat ? (invoiceItem[0]?.vat_rate || 0) : 0)) / 100)}</p> : ''
                                                        }
                                                        <p className='d-flex justify-content-end mb-0'>{formatCurrency(getVisibleSum() + ((getVisibleSum() * (invoiceItem[0]?.is_charge_vat ? (invoiceItem[0]?.vat_rate || 0) : 0)) / 100))}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <h6>Payment(s)</h6>
                                    <Table className='w-100 job_table' bordered>
                                        <tbody>
                                        {(invoiceId ? invoiceData?.payments : invoicePayment)?.map((data: any) => {
                                                return (
                                                    <tr>
                                                        <td style={{width: '15%'}}>
                                                            <p className='m-0 fw-bold cursor-pointer' 
                                                                style={{color: '#495057'}}  
                                                                onClick={() => setPaymentModal({ isOpen: true, payment_id: data?.id, type: data?.is_booking_deposit ? 'deposit' : 'payment' })}
                                                            >
                                                                {data?.is_booking_deposit ? 'Booking Deposit' : data?.method?.name + ' Payment'} 
                                                            </p>
                                                        </td >
                                                        <td style={{width: '9%'}}> {data?.date ? moment(data?.date).format('DD/MM/YYYY') : ''}</td>
                                                        <td>
                                                            <p className='m-0' style={{color: '#495057'}}>{data?.notes}</p>
                                                        </td>
                                                        <td style={{width: '13%'}}></td>
                                                        <td style={{width: '10%'}}></td>
                                                        <td style={{width: '13%'}}>
                                                            <p className='d-flex justify-content-end pe-3 m-0'>{formatCurrency(data?.amount)}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan={4}></td>
                                                <td style={{width: '10%'}}>
                                                    <p className='d-flex justify-content-end m-0'>Balance</p>
                                                </td>
                                                <td style={{width: '13%'}}>
                                                    <p className='d-flex justify-content-end m-0 pe-3'>{formatCurrency(getVisibleSum() + ((getVisibleSum() * (invoiceItem[0]?.is_charge_vat ? (invoiceItem[0]?.vat_rate || 0) : 0)) / 100) - getPaymentSum())}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                            <Row className='px-5'>
                                <Col md={6}>
                                    <Label className='form-label' for='notes'>Invoice Notes</Label>
                                    <Input 
                                        type='textarea' 
                                        rows='3' 
                                        placeholder='Enter Invoice Notes'
                                        value={invoice_note} 
                                        disabled={invoiceId}
                                        onChange={(e) => setInvoice_note(e.target.value)} 
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-10 p-5'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        { invoiceId && 
                                            <>
                                                {currentUser?.level == 1 && 
                                                    <Button type="button" color="danger" onClick={() => setCenteredModal(true)}>
                                                        Delete
                                                    </Button>
                                                }
                                                {currentStatus?.value == 2 &&
                                                    <>
                                                        <Button type="button" color="primary" className='mx-2' onClick={() => setPaymentModal({ isOpen: true, payment_id: null, type: 'payment' })}>
                                                            Record Payment
                                                        </Button>
                                                        <Button type="button" color="primary"
                                                            disabled={loader === 'stripe_payment'}
                                                            onClick={stripePayment}
                                                        >
                                                            {loader === 'stripe_payment' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Stripe Payment</span>}
                                                        </Button>
                                                    </>
                                                }
                                                {currentStatus?.value == 1 &&
                                                    <Button type="button" color="primary" className='ms-2'
                                                        disabled={loader === 'issue'}
                                                        onClick={() => setIssueModal(true)}
                                                    >
                                                        {loader === 'issue' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Issue</span>}
                                                    </Button>
                                                }
                                            </>
                                        }
                                        {(!invoiceId || currentStatus?.value == 1) &&
                                            <Button type="button" color="primary" className='ms-2'
                                                onClick={() => setOpenModifyLine({ open: true, id: null, data: { date: null, description: null, rate: null, qty: null, edit_custom: 'add' } })}
                                            >
                                                Add Line Item
                                            </Button>
                                        }
                                    </div>
                                    <div>
                                        <Button type="button" color="secondary" onClick={() => setOpenInvoice(false)} style={{ marginRight: "10px" }}>
                                            Close
                                        </Button>
                                        {invoiceId &&
                                            <Button type="button" color="primary" style={{ marginRight: "10px" }} disabled={loader === 'pdf'}>
                                                <a onClick={() => downloadInvoicePdf(invoiceId)}>
                                                    { loader === 'pdf' ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>View PDF</span>}
                                                </a>
                                            </Button>
                                        }
                                        {(invoiceId && currentStatus?.value != 1) ? '' :
                                            <Button type='submit' disabled={loader === 'submit'} color='primary' className='btn-next'>
                                                { loader === 'submit' ? <Spinner size='sm'/> : <span className='align-middle d-sm-inline-block d-none'>{ invoiceId ? 'Update' : 'Create' } Invoice</span>}
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
            </UILoader>
        </Modal>
        <ConfirmModal
            isOpen={centeredModal}
            onHide={() => setCenteredModal(false)}
            ondelete={forceDeleteInvoice}
            loader={loader === 'delete'}
        />
        <ConfirmModal
            title='Add Invoice Line Item'
            titleAlign={true}
            body={
                <>
                    <Row>
                        <Col md='3' className='d-flex align-items-center justify-content-start'>
                            <p className='m-0'>Item text</p>
                        </Col>
                        <Col md='9'>
                            <Input type='textarea' placeholder='Enter text' value={lineItem_text} onChange={(e) => setLineItem_text(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col md='3' className='d-flex align-items-center justify-content-start'>
                            <p className='m-0'>Item Amount</p>
                        </Col>
                        <Col md='9'>
                            <div className='w-50'>
                                <Input type='number' min={0} placeholder='Enter amount' value={lineItem_amount} onChange={(e) => setLineItem_amount(Number(e.target.value))} />
                            </div>
                        </Col>
                    </Row>
                </>
            }
            yes='Add'
            no='Cancel'
            isOpen={lineItemModal}
            onHide={() => setLineItemModal(false)}
            ondelete={addLineItem}
        />
        <IssueInvoice
            issueModal={issueModal} 
            setIssueModal={setIssueModal}
            invoiceId={invoiceId}
            render={render}
            setRender={setRender}
            currentCustomer={currentCustomer}
            setRenderElementService={setRenderElementService}
        />
        <ConfirmModal
            title=''
            body={
                <div>
                    <div>Are you sure you wish to issue this invoice ?</div>
                    <div className='d-flex my-5'>
                        <div style={{width: '40px'}} >
                            <Input type='checkbox' checked={isInvoiceEmail} onChange={(e) => setIsInvoiceEmail(e.target.checked)} />
                        </div>
                        <p className='mb-0'>Email invoice to Client</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <p className='mb-0' style={{marginLeft: '40px', width: '100px'}}>Email to</p>
                        <ReactSelect
                            isClearable={false}
                            className='react-select w-100'
                            classNamePrefix='select'
                            placeholder='Select Status'
                            isDisabled={!isInvoiceEmail}
                            styles={customSelectStyles(null)}
                            theme={selectThemeColors}
                            options={emailClientOption}
                            value={currentClientEmail}
                            onChange={(data: any) => {
                                setCurrentClientEmail(data)
                            }}
                        />
                    </div>
                </div>
            }
            yes='Yes'
            no='No'
            isOpen={centeredIssueModal}
            onHide={() => setCenteredIssueModal(false)}
            ondelete={issuePayment}
            loader={loader === 'issue'}
        />
        <InvoicePayment
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            invoiceId={invoiceId}
            render={render}
            setRender={setRender}
            currentCustomer={currentCustomer}
            setRenderElementService={setRenderElementService}
        />
        <EditLineItem
            openModifyLine={openModifyLine}
            setOpenModifyLine={setOpenModifyLine}
            loader={loader === 'modify_line'}
            setModifyLineData={setModifyLineData}
            currentInvoiceMethod={currentInvoiceMethod}
        />
    </>
  )
}

export default InvoiceModel