import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Row, Spinner } from 'reactstrap'
import { EntityService } from '../../../modules/Services/Entity'
import toast from 'react-hot-toast'
import { userInfo } from "../../../modules/auth/core/_requests"
import { useAuth } from "../../../modules/auth"

function TaxConfiguration({ entityDetail }) {
  const entity_id = localStorage.getItem('entity_id')
  const { setCurrentUser, logout } = useAuth()
  const [isChargeVat, setIsChargeVat] = useState(false)
  const [vat_rate, setVat_rate] = useState(null)
  const [loading, setLoading] = useState(null)

  function editData() {
    setIsChargeVat(entityDetail?.is_charge_vat)
    setVat_rate(entityDetail?.vat_rate)
  }

  useEffect(() => {
    if(entityDetail) editData()
  }, [entityDetail])

  function saveTax() {
    setLoading('update')
    const data = {
      is_charge_vat: JSON.stringify(+isChargeVat),
      vat_rate: vat_rate
    }

    EntityService.updateEntityProfile(entity_id, data)
      .then((res: any) => {
        toast.success(res?.data?.message)

        userInfo(localStorage.getItem('entity_id'))
          .then((res: any) => {
            setCurrentUser(res?.data)
            localStorage.setItem('auth_user', JSON.stringify(res?.data))
            localStorage.setItem('entity_id', '1')
          })
          .catch((err: any) => {
            toast.error(err?.response?.data?.message)
            logout()
          })
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message)
      })
      .finally(() => setLoading(null))
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className='dropdown-header'>Tax</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="mt-3">
          <Label className='form-label' for='postCode'>Tax Settings</Label>
          <Col md='12' className='my-2'>
            <Input
              type="checkbox"
              className="me-4"
              checked={isChargeVat}
              onChange={(e) => setIsChargeVat(e.target.checked)}
            />
            <Label className='form-label' for='postCode'>Charge VAT</Label>
          </Col>
          <Col md='12' className='mb-4 d-flex align-items-center'>
            <Input
              placeholder="VAT"
              type="number"
              disabled={!isChargeVat}
              className="me-5"
              style={{ width: '150px' }}
              value={vat_rate}
              onChange={(e) => setVat_rate(e.target.value)}
            />
            <Label className='form-label' for='postCode'>VAT rate</Label>
          </Col>
        </Row>
        <div className='d-flex justify-content-end mt-10'>
          <div>
            <Button type='submit' color='primary' className='btn-sm' disabled={loading === 'update'} onClick={() => saveTax()}>
              {loading === 'update' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Save</span>}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default TaxConfiguration