import React, {Fragment, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon,} from '../../../_metronic/helpers'
import { useNavigate } from 'react-router'

import toast from 'react-hot-toast'
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner'
import moment from 'moment'
import { Col, Input, Row, Table } from 'reactstrap'
import { JobLegsService } from '../../modules/Services/JobLegs'
import SmsModel from './SmsModel'
import ReactSelect from 'react-select'
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils'
import { DriverService } from '../../modules/Services/Drivers'
import { CustomerService } from '../../modules/Services/Customers'
import AsyncSelect from 'react-select/async'
import UILoader from '../../modules/commonModel/Ui-loader'
import { Icon } from '@iconify/react'
import { FilterDetails } from '../../App'
import { TagsService } from '../../modules/Services/Tags'

import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import ChangeJobStatus from './ChangeJobStatus'
import MultiDatePickr from '../../modules/commonModel/MultiDatePickr'
import AllocateResource from './AllocateResource'
import AllocateTags from './AllocateTags'
import { useAuth } from '../../modules/auth'

const JobLegsPage = () => {
  const tableHearder = [
    {label: 'Job ID', asc_desc: undefined, value: 'job_number', style: 'pointer'},
    {label: 'Pickup Date', asc_desc: undefined, value: 'start_at_date', style: 'pointer'},
    {label: 'Client', asc_desc: undefined, value: null, style: ''},
    {label: 'Details', asc_desc: undefined, value: null, style: ''},
    {label: 'Tags', asc_desc: undefined, value: null, style: ''},
    {label: 'PAX/Vehicles', asc_desc: undefined, value: null, style: ''},
    {label: 'Vehicle', asc_desc: undefined, value: null, style: ''},
    {label: 'Driver', asc_desc: undefined, value: null, style: ''}
  ]

  const [pending, setPending] = useState(false)
  const [filterValue, setFilterValue] = useContext(FilterDetails)
  const navigate = useNavigate()
  const [jobLegsData, setJobLegsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const entity_id = localStorage.getItem('entity_id')
  const [currentCustomer, setCurrentCustomer] = useState((Object.keys(filterValue?.jobs?.customer).length > 0) ? filterValue?.jobs?.customer : { value: '', label: 'Select Client' })
  const [searchTerm, setSearchTerm] = useState(filterValue?.jobs?.job_search)
  const [statusData, setStatusData] = useState([])
  const [currentStatus, setCurrentStatus] = useState((Object.keys(filterValue?.jobs?.status).length > 0) ? filterValue?.jobs?.status : [])
  const [currentDriver, setCurrentDriver] = useState((Object.keys(filterValue?.jobs?.driver).length > 0) ? filterValue?.jobs?.driver : { value: '', label: 'Select Driver' })
  const [renderElementService, setRenderElementService] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [openSmsModel, setOpenSmsModel] = useState(false)
  const [tableHearderField, setTableHearderField] = useState(tableHearder)
  const [sortOrder, setSortOrder] = useState((Object.keys(filterValue?.jobs?.order_by).length > 0) ? filterValue?.jobs?.order_by : 'desc');
  const [sort, setSort] = useState((Object.keys(filterValue?.jobs?.sort_by).length > 0) ? filterValue?.jobs?.sort_by : 'start_at_date');
  const [filter_tagData, setFilterTagData] = useState([{ value: '', label: 'Select Tag'}])
  const [tagData, setTagData] = useState([{ value: '', label: 'Select Tag'}])
  const [currentTag, setCurrentTag] = useState((Object.keys(filterValue?.jobs?.tag).length > 0) ? filterValue?.jobs?.tag : { value: '', label: 'Select Tag' })
  const [changeCheckbox, setChangeCheckbox] = useState([])
  const [selectAll, setSelectAll] = useState({
    all: false,
    checkbox: false,
    count: 0
  })

  const { currentUser } = useAuth()
  const [driverOptions, setDriverOptions] = useState([])
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange<Dayjs>>((Object.keys(filterValue?.jobs?.date).length > 0) ? filterValue?.jobs?.date : [null, null]);
  const [jobDate, setJobDate] = useState((Object.keys(filterValue?.jobs?.date).length > 0) ? filterValue?.jobs?.date : [null, null]);
  const [currentSelected, setCurrentSelected] = useState({ value: null, label: 'With Selected' })
  // const [openJobStatus, setOpenJobStatus] = useState(false)
  const [openAllocateResource, setOpenAllocateResource] = useState(false)
  const [openAllocateTags, setOpenAllocateTags] = useState(false)
  
  const withSelectedData = [
    { value: null, label: 'With Selected' },
    // { value: 'change_status', label: 'Change Status' },
    { value: 'allocate_resources', label: 'Allocate Resources' },
    { value: 'allocate_tags', label: 'Add Tag' }
  ]

  const statusIds = currentStatus?.map((item: any) => {
    return item?.value
  })

  let driverTimeoutId: ReturnType<typeof setTimeout> | null = null;
  let customerTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const loadOptions = (inputValue: any, callback: any) => {
    if (customerTimeoutId) {
      clearTimeout(customerTimeoutId);
    }
    customerTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,customer_name',
        customer_name : inputValue
      }
      CustomerService.getCustomerList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.customer_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getStatusList() {
    JobLegsService.jobLegStatus(entity_id)
    .then((res: any) => {
      const statusList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.label }
      })
      const defaultStatus = { value: null, label: 'Select Status' }
      setStatusData(statusList)
      // setStatusData([defaultStatus, ...statusList])
    })
    .catch((err: any) => {
      // toast.error(err?.response?.data?.message)
      console.log('err 123', err?.response?.data?.message)
    })
  }

  const loadDriverOptions = (inputValue: any, callback: any) => {
    if (driverTimeoutId) {
      clearTimeout(driverTimeoutId);
    }
    driverTimeoutId = setTimeout(() => {
      const obj = { 
        getall: 1,
        fields: 'id,driver_name',
        driver_name : inputValue
      }
      DriverService.getDriverList(entity_id, obj)
      .then((res: any) => {
        callback(res?.data?.response?.map((item: any) => {
          return { value: item?.id, label: item?.driver_name}
        }))
      })
      .catch(err => console.log(err?.response?.data?.message))
    }, 1000)
  }

  function getTagList() {
    const data = {
      getall: 1,
      fields: 'id,name'
    }
    TagsService.getTagsList(entity_id, data)
    .then((res: any) => {
      const tagsList = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.name }
      })
      setFilterTagData(pre => [...pre, ...tagsList])
      setTagData(tagsList)
    })
    .catch((err: any) => {
      // toast.error(err?.response?.data?.message)
      console.log('err 162', err?.response?.data?.message)
    })
  }

  const getDriverList = () => {
    const obj = { 
      getall: 1,
      fields: 'id,driver_name'
    }
    DriverService.getDriverList(entity_id, obj)
    .then((res: any) => {
      const data = res?.data?.response?.map((item: any) => {
        return { value: item?.id, label: item?.driver_name}
      });
      const defaultDriver = {value: '', label: 'Select Driver'};
      setDriverOptions([defaultDriver, ...data])
    })
    .catch(err => console.log(err?.response?.data?.message))
  }

  useEffect(() => {
    getStatusList()
    getTagList()
    getDriverList()
  }, [])

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        scrollTop()
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    } else {
      scrollTop()
    }
  }, [searchTerm])

  useEffect(() => {
    if (renderElementService) {
      setRenderElementService(0)
      getJobLegs()
    }
  }, [renderElementService])

  const getJobLegs= () => {
    setPending(true)

    JobLegsService.getJobLegsList(entity_id, {
      per_page: 12,
      page: currentPage,
      customer: currentCustomer?.value ? currentCustomer?.value : null,
      filter_status: statusIds?.length ? statusIds : null,
      driver: currentDriver?.value ? currentDriver?.value : null, 
      start_date : jobDate?.length ? (jobDate[0] ? moment(jobDate[0]?.toISOString()).format("YYYY-MM-DD") : null) : null,
      end_date : jobDate?.length ? (jobDate[1] ? moment(jobDate[1]?.toISOString()).format("YYYY-MM-DD") : null ): null,
      sort_by: sortOrder ? sortOrder : null,
      order_by: sort ? sort : null,
      filter_tag: currentTag?.value ? currentTag?.value : null,
      search: searchTerm ? searchTerm : null
    })
    .then((res: any) => { 
      if (currentPage > 1) {
        setJobLegsData(pre => [...pre, ...res?.data?.response?.data]) 
        if (selectAll?.checkbox) {
          const newLoadedIds = []
          res?.data?.response?.data?.map((item: any) => newLoadedIds.push(item?.id))
          setChangeCheckbox((prev) => [...prev, ...newLoadedIds])
          setSelectAll((prev) => ({...prev, count: (changeCheckbox?.length + newLoadedIds?.length)}))
        }
      }
      else setJobLegsData(res?.data?.response?.data)
      setNextPage(res?.data?.response?.next_page_url)
    })
    .catch((err: any) => {
      // toast.error(err?.response?.data?.message)
      console.log('err 237', err?.response?.data?.message)
    })
    .finally(() => setPending(false))
  }

  function handleScroll(event: any) {
    const scrollHeight = event.target.scrollTop + event.target.clientHeight
    const elementHeight = event.target.scrollHeight
    if (event.target.scrollTop > 0) {
      if (((scrollHeight + 100) >= elementHeight) && nextPage && !pending && !renderElementService) {
        setCurrentPage((p: any) => p + 1)
        setRenderElementService((prev) => prev + 1)
      }
    }
  }

  function scrollTop() {
    const table = document.getElementById('jobs_table')
    table.scrollTo(0, 0)
    setCurrentPage(1)
    setRenderElementService(1)
  }

  function onChangeFilter(event: any, fieldText: any) {
    setSortOrder('asc');
    const sort = tableHearderField?.map((item: any) => {
      if(item?.value == fieldText) {
        if(event) return {...item, asc_desc: false};
        return {...item, asc_desc: true};
      } else {
        return {...item, asc_desc: undefined}
      }
    })
    setTableHearderField(sort);
    if (event) setSortOrder('desc');
    setSort(fieldText);
    scrollTop();
    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, sort_by: fieldText, order_by: event ? 'desc' : 'asc'}})
  }

  function handleSearch(val: any) {
    setSearchTerm(val)
    setFilterValue({...filterValue, jobs: { ...filterValue?.jobs, job_search: val }})
  }

  function changeper(id: any, value: any) {
    let UpdateList = [...changeCheckbox]
    if (value) {
      UpdateList = [...changeCheckbox, id]
      setSelectAll((prev) => ({...prev, count: UpdateList?.length}))
    } else {
      UpdateList.splice(changeCheckbox.indexOf(id), 1)
      setSelectAll((prev) => ({...prev, all: false, checkbox: false, count: UpdateList?.length}))
    }
    setChangeCheckbox(UpdateList)
  }

  function handleSelectAll(value: any) {
    if (value) {
      const data = jobLegsData?.map((item: any) => item?.id)
      setChangeCheckbox(data)
      setSelectAll((prev) => ({...prev, checkbox: true, count: data?.length}))
    } else {
      setChangeCheckbox([])
      setSelectAll((prev) => ({...prev, all: false, checkbox: false, count: 0 }))
    }
  }

  const handleClearDate = () => {
    scrollTop();
    setJobDate([null, null]);
    setSelectedDateRange([null, null]);
    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, date: [null, null]}})
  };

  const handleDateChange = () => {
    setJobDate(selectedDateRange)
    setFilterValue({ ...filterValue, jobs: { ...filterValue?.jobs, date: selectedDateRange } })
    scrollTop()
  }

  return (
    <>
      <SmsModel
        openSmsModel={openSmsModel}
        setOpenSmsModel={setOpenSmsModel}
        changeCheckbox={changeCheckbox}
        selectAll={selectAll}
        setRenderElementService={setRenderElementService}
        currentCustomer={currentCustomer}
        statusIds={statusIds}
        currentDriver={currentDriver}
        jobDate={jobDate}
        filter_tag={currentTag}
        searchTerm={searchTerm}
      />
      {/* <ChangeJobStatus
        openJobStatus={openJobStatus}
        setOpenJobStatus={setOpenJobStatus}
        statusData={statusData}
        changeCheckbox={changeCheckbox}
        selectAll={selectAll}
        setRenderElementService={setRenderElementService}
        currentCustomer={currentCustomer}
        statusIds={statusIds}
        currentDriver={currentDriver}
        jobDate={jobDate}
        filter_tag={currentTag}
        searchTerm={searchTerm}
      /> */}
      <AllocateResource
        openAllocateResource={openAllocateResource}
        setOpenAllocateResource={setOpenAllocateResource}
        changeCheckbox={changeCheckbox}
        selectAll={selectAll}
        jobLegsData={jobLegsData}
        setRenderElementService={setRenderElementService}
        currentCustomer={currentCustomer}
        statusIds={statusIds}
        currentDriver={currentDriver}
        jobDate={jobDate}
        filter_tag={currentTag}
        searchTerm={searchTerm}
      />
      <AllocateTags
        openAllocateTags={openAllocateTags}
        setOpenAllocateTags={setOpenAllocateTags}
        tagData={tagData}
        changeCheckbox={changeCheckbox}
        setRenderElementService={setRenderElementService}
        selectAll={selectAll}
        currentCustomer={currentCustomer}
        statusIds={statusIds}
        currentDriver={currentDriver}
        jobDate={jobDate}
        filter_tag={currentTag}
        searchTerm={searchTerm}
      />
      
      <KTCard className='mb-3'>
        <KTCardBody className='p-5'>
          <Row>
            <Col md= '2' className='d-flex align-items-center'>
                <Input type='search' placeholder='Search Jobs' value={searchTerm} onChange={(e) => handleSearch(e.target.value)}  />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
                <AsyncSelect
                  isClearable={true}
                  className='react-select w-100'
                  classNamePrefix='select'
                  placeholder='Select Client'
                  isRtl={false}
                  name='callback-react-select'
                  styles={customSelectStyles(null)}
                  loadOptions={loadOptions}
                  theme={selectThemeColors}
                  value={currentCustomer}
                  cacheOptions
                  onChange={(data: any) => {
                    scrollTop()
                    setCurrentCustomer(data)
                    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, customer: data || { value: '', label: 'Select Client' }}})
                  }}
                />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              <ReactSelect
                isMulti
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                styles={customSelectStyles(null)}
                options={statusData}
                value={currentStatus}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentStatus(data)
                  setFilterValue({...filterValue, jobs: {...filterValue?.jobs, status: data}})
                }}
              />
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              {currentUser?.dropdown_counts?.drivers_count < 1000 ?
                <ReactSelect
                  isClearable={false}
                  className='react-select w-100'
                  classNamePrefix='select'
                  theme={selectThemeColors}
                  styles={customSelectStyles(null)}
                  options={driverOptions}
                  value={currentDriver}
                  onChange={(data: any) => {
                    scrollTop()
                    setCurrentDriver(data)
                    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, driver: data  || { value: '', label: 'Select' }}})
                  }}
                />
                :
                <AsyncSelect
                  isClearable={true}
                  className='react-select w-100'
                  classNamePrefix='select'
                  placeholder='Search Driver'
                  isRtl={false}
                  name='callback-react-select'
                  theme={selectThemeColors}
                  styles={customSelectStyles(null)}
                  loadOptions={loadDriverOptions}
                  value={currentDriver}
                  cacheOptions
                  onChange={(data: any) => {
                    scrollTop()
                    setCurrentDriver(data)
                    setFilterValue({...filterValue, jobs: {...filterValue?.jobs, driver: data  || { value: '', label: 'Select' }}})
                  }}
                />
              }
            </Col>
            <Col md= '2' className='d-flex align-items-center'>
              <ReactSelect
                isClearable={false}
                isSearchable={false}
                className='react-select w-100 text-start'
                classNamePrefix='select'
                theme={selectThemeColors}
                styles={customSelectStyles(null)}
                options={filter_tagData}
                value={currentTag}
                onChange={(data: any) => {
                  scrollTop()
                  setCurrentTag(data)
                  setFilterValue({...filterValue, jobs: {...filterValue?.jobs, tag: data}})
                }}
              />
            </Col>
            <Col className='col-2 d-flex justify-content-end'>
              <div className="d-flex" style={{width: '300px'}}>
                <MultiDatePickr
                  handleClearDate={handleClearDate}
                  handleDateChange={handleDateChange}
                  date={jobDate}
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                />
              </div>
            </Col>
          </Row>
        </KTCardBody>
      </KTCard>
      <KTCard>
        <KTCardBody className='p-0 pt-1'>
          <div className='invoice-list-table-header w-100 px-10 my-5'>
            <Row>
              <Col md="2" className='d-flex align-items-center p-0'>
                <ReactSelect
                  isClearable={false}
                  isSearchable={false}
                  className='react-select w-100 text-start'
                  classNamePrefix='select'
                  theme={selectThemeColors}
                  styles={customSelectStyles(null)}
                  isDisabled={changeCheckbox?.length === 0}
                  options={withSelectedData}
                  value={currentSelected}
                  onChange={(data: any) => {
                    setCurrentSelected(data)
                    // if (data?.value === 'change_status') setOpenJobStatus(true)
                    // else 
                    if (data?.value === 'allocate_resources') setOpenAllocateResource(true)
                    else if (data?.value === 'allocate_tags') setOpenAllocateTags(true)
                  }}
                />
              </Col>
              <Col md="10" className='d-flex align-items-center justify-content-end p-0'>
                <div className="d-flex flex-row align-items-center">
                  <a href='#'
                     className={`btn btn-sm btn-primary ${changeCheckbox?.length === 0 ? 'disabled' : ''}`} 
                     onClick={() => setOpenSmsModel(true)}>
                    <KTIcon iconName='plus' className='fs-3' />
                    Notify Drivers
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <UILoader blocking={ pending } loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={pending ? 'loader-blur' : ''}>
            <div onScroll={(e) => handleScroll(e)} id='jobs_table' className='jobs-table-style'>
              <Table className='jobs-table'>
                <thead className='sticky-header'>
                  <tr style={{fontWeight: '500'}}>
                    <th className='ps-3'>
                      <Input type='checkbox' checked={selectAll?.checkbox} onChange={(e) => {
                        handleSelectAll(e.target.checked)
                        
                      }} />
                    </th>
                    {
                      selectAll.checkbox ? 
                      <th colSpan={tableHearderField?.length}>
                        <div>
                          { selectAll?.all ? 'All in the result selected.'  : `${selectAll?.count} Selected.` } 
                          <span style={{ color: 'blue', cursor: 'pointer', paddingLeft: '5px', fontWeight: '500' }} onClick={() => setSelectAll((prev) => ({...prev, all: !prev?.all}))}>
                            { selectAll?.all ? 'Undo' : 'Select all in this result set.' }  
                          </span>
                        </div>
                      </th> : 
                      tableHearderField?.map((item: any, index: any) => {
                        return (
                          <th className='ps-3' key={index}>
                            <div onClick={() => { item?.value ? onChangeFilter(item?.asc_desc, item?.value) : '' }} style={{cursor: item?.style, color: 'rgba(0, 0, 0, 0.87)'}}>
                              {item.label}
                              {item?.value && item.asc_desc !== undefined &&
                                <span>
                                  {item.asc_desc ? <Icon icon="raphael:arrowdown" /> : <Icon icon="raphael:arrowup" />}                                                            
                                </span>
                              }
                            </div>
                          </th>
                        )
                      })
                    }
                    
                    <th className='ps-3'>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {jobLegsData?.map((item: any, i: any) => {
                    return (
                      <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                        <td className='ps-3' style={{width: '40px'}}>
                          <Input type='checkbox' value={item?.id} checked={changeCheckbox.includes(item?.id)} onChange={(e) => changeper(item?.id, e.target.checked)} />
                        </td>
                        <td className='ps-3' style={{width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <span onClick={() => navigate(`/jobs/${item?.id}/edit`)} className='fw-bold cursor-pointer fs-6 theme-color' >
                            {item?.job_number}
                          </span>
                          <div style={{color: 'rgba(0, 0, 0, 0.87)'}}>{item?.status?.label}</div>
                        </td>
                        <td style={{width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{item?.start_at_date ? moment(item?.start_at_date).format('DD-MM-YYYY') : ''} { item?.start_at_time ? item?.start_at_time : '' } </div>
                          {/* <div>{item?.end_at_date ? moment(item?.end_at_date).format('DD-MM-YYYY') : ''} { item?.end_at_time ? item?.end_at_time : '' }</div> */}
                        </td>
                        <td style={{width: '250px', overflow: 'hidden'}}>
                          <div>{item?.customer_name}</div>
                          <div>{item?.company_name}</div>
                        </td>
                        <td style={{maxWidth: '150px', overflow: 'hidden'}}>
                          <div>
                            {item?.pick_up_location ? (<><b className='me-2'><Icon icon="bi:pin-map-fill" style={{color: '#f68716'}} /> </b>{item.pick_up_location}</>) : null}
                          </div>
                          <div>
                            {item?.drop_off_location ? (<><b className='me-2'><Icon icon="bi:arrow-right-circle-fill" style={{color: '#f68716'}} /> </b>{item.drop_off_location}</>) : null}
                          </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div className='d-flex'>
                            {item?.tags?.map((item: any, i: any) => {
                              return (
                                <span className='px-2 me-2' key={i} style={{color: item?.font_color ? item?.font_color : 'white', backgroundColor: item?.color, borderRadius: '5px'}}>{item?.name}</span>
                              )
                            })}
                          </div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          <div>{(item?.pax ? item?.pax : 'N/A') + ' / ' + item?.vehicles_required}</div>
                          <div>{item?.job_reference}</div>
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                            if (data?.vehicle?.vehicle_name) {
                              return <div key={i}>{data?.vehicle?.vehicle_name}</div>
                            }
                          })}
                        </td>
                        <td style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {item?.driver_vehicles_arr?.map((data: any, i: any) => {
                            if (data?.driver?.driver_name) {
                              return <div key={i}>{data?.driver?.driver_name}</div>
                            }
                          })}
                        </td>
                        <td className='ps-3'>
                          {item?.is_notified ?
                            <Icon icon="fa:bell" width="1.5rem" height="1.5rem" style={{color: '#f68716'}}/>
                            : ''
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {jobLegsData?.length === 0 ?
                <p className='d-flex justify-content-center'>There are no Jobs to display</p>
                : ''
              }
            </div>
          </UILoader>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {JobLegsPage}
