import {Suspense, createContext, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
// import "flatpickr/dist/themes/material_green.css";

// sentry
import * as Sentry from "@sentry/react";

export const FilterDetails = createContext(null)

// Sentry.init({
//   dsn: import.meta.env.VITE_APP_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const App = () => {
  const [filterValue, setFilterValue] = useState({
    jobs: {
      job_search: '',
      customer: {},
      status: [],
      driver: {},
      tag: {},
      date: [null, null],
      jobShades: 'status',
      sort_by: 'start_at_date',
      order_by: 'desc'
    },
    bookings: {
      booking_search: {},
      customer: {},
      status: {},
      tag: {},
      payment: {},
      date: [null, null],
      sort_by: 'start_at_date',
      order_by: 'desc'
    },
    customers: {
      customer_search: '',
      contact_search: ''
    },
    drivers: {
      driver_search: '',
      sort_by: '',
      order_by: ''
    },
    vehicles: {
      vehicle_search: '',
      sort_by: '',
      order_by: ''
    },
    locations: {
      location_search: ''
    },
    planner: {
      date: {},
      search: ''
    },
    invoice: {
      invoice_search: '',
      customer: {},
      date: [null, null],
      status: {}
    },
    users: {
      user_search: '',
      sort_by: '',
      order_by: ''
    }
  })

  useEffect(() => {
    const isProd = import.meta.env.VITE_REACT_APP_ENV != "prod"; // Read from .env file
    document.documentElement.style.setProperty("--sidebar-margin-top", isProd ? "30px" : "0px");
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <FilterDetails.Provider value={[filterValue, setFilterValue]}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
      </FilterDetails.Provider>
    </Suspense>
  )
}

export {App}
