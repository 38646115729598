import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import ReactSelect from 'react-select'
import Flatpickr from 'react-flatpickr';
import { customSelectStyles, selectThemeColors } from '../../modules/commonModel/Utils';
import { InvoiceService } from '../../modules/Services/Invoice';
import toast from 'react-hot-toast';
import moment from 'moment';
import UILoader from '../../modules/commonModel/Ui-loader';
import ComponentSpinner from '../../modules/apps/user-management/users-list/components/loading/Loading-spinner';

function BookingDeposit({ depositModal, setDepositModal, setRenderElementService }) {
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            deposit_date: new Date(),
            deposit_method: null,
            deposit_amount: '',
            deposit_note: ''
        }
    });
    const entity_id = localStorage.getItem('entity_id')
    const [loader, setLoader] = useState(null)
    const [methodOption, setMethodOption] = useState(null)

    function getDepositSatus() {
        InvoiceService.getDepositStatusList(entity_id)
            .then((res: any) => {
                const data = res.data?.map((item: any) => {
                    return { value: item?.id, label: item?.name }
                })
                setMethodOption(data)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onSubmit = (data: any) => {
        setLoader('create')
        const payload = {
            entity_id: entity_id,
            booking_id: depositModal?.booking_id,
            customer_id: depositModal?.customer_id,
            date: data?.deposit_date ? (typeof data?.deposit_date == 'object' ? moment(data?.deposit_date[0]).format('YYYY-MM-DD') : data?.deposit_date) : null,
            method_id: data?.deposit_method?.value,
            amount: data?.deposit_amount,
            notes: data?.deposit_note
        }

        InvoiceService.createDeposit(entity_id, payload)
            .then((res: any) => {
                toast.success(res?.data?.message)
                setDepositModal({ isOpen: false, booking_id: null, customer_id: null })
                setRenderElementService(1)
            })
            .catch((err: any) => {
                toast.error(err?.response?.data?.message)
            })
            .finally(() => setLoader(null))
    }

    useEffect(() => {
        if (depositModal?.isOpen) {
            getDepositSatus()
        } else reset(
            {
                deposit_date: new Date(),
                deposit_method: null,
                deposit_amount: '',
                deposit_note: ''
            }
        )
    }, [depositModal])

    return (
        <Modal isOpen={depositModal?.isOpen} className="modal-dialog-centered modal-lg" toggle={() => setDepositModal({ isOpen: false, deposit_id: null, customer_id: null })} >
            <ModalBody className="px-sm-5">
                <UILoader blocking={loader === 'edit'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'edit' ? 'loader-blur' : ''}>
                    <Row className='my-5'>
                        <h3 className='d-flex justify-content-center'>{depositModal?.deposit_id ? 'Edit' : 'Add'} Deposit</h3>
                    </Row>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className='p-5 pb-0'>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-6'>Date</Label>
                                <Controller name='deposit_date' control={control} rules={{ required: true }}
                                    render={({ field }) => (
                                        <Flatpickr
                                            {...field}
                                            className='form-control'
                                            placeholder='Select Date'
                                            options={{
                                                enableTime: false,
                                                altInput: true,
                                                dateFormat: 'Y-m-d H:i',
                                                disableMobile: true
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-3'>Method</Label>
                                <Controller name='deposit_method' control={control} rules={{ required: true }}
                                    render={({ field }) => (
                                        <ReactSelect
                                            {...field}
                                            isClearable={false}
                                            className='react-select w-100'
                                            classNamePrefix='select'
                                            placeholder='Select Status'
                                            name='callback-react-select'
                                            styles={customSelectStyles(errors.deposit_method)}
                                            theme={selectThemeColors}
                                            options={methodOption}
                                            onChange={(data) => {
                                                field.onChange(data)
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md='4' className='d-flex'>
                                <Label className='d-flex align-items-center m-0 me-3'>Amount</Label>
                                <Controller name='deposit_amount' control={control} rules={{ required: true }}
                                    render={({ field }) => (
                                        <Input {...field} type='number' placeholder='Enter Amount'
                                            style={{ border: errors.deposit_amount && '1px solid #ea5455' }}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className='ps-5'>
                            <Col md='4'>
                                {errors?.deposit_date && (<small className="text-danger ms-16">Date is a required field</small>)}
                            </Col>
                            <Col md='4'>
                                {errors?.deposit_method && (<small className="text-danger ms-16">Method is a required field</small>)}
                            </Col>
                            <Col md='4'>
                                {errors?.deposit_amount && (<small className="text-danger ms-16">Amount is a required field</small>)}
                            </Col>
                        </Row>
                        <Row className='p-5'>
                            <Col md={12} className='d-flex'>
                                <Label className='form-label me-3' for='notes'>Notes</Label>
                                <Controller name='deposit_note' control={control} rules={{ required: false }}
                                    render={({ field }) => (
                                        <Input type='textarea' rows='2' placeholder='Enter Notes' {...field} />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className='p-5'>
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <Button type="button" size='sm' color="secondary" className='me-2' onClick={() => setDepositModal({ isOpen: false, deposit_id: null, customer_id: null })}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' size='sm' disabled={loader === 'create'} color='primary'>
                                        {loader === 'create' ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>{depositModal?.deposit_id ? 'Update' : 'Add'}</span>}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </Form>
                </UILoader>
            </ModalBody>
        </Modal>
    )
}

export default BookingDeposit