import React, { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Input, Row, Spinner, Table } from 'reactstrap'
import { SettingsService } from '../../../modules/Services/Settings';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

function DomainConfiguration() {
    const entity_id = localStorage.getItem('entity_id');
    const [domain, setDomain] = useState('');
    const [domainConfig, setDomainConfig] = useState(null);
    const [loader, setLoader] = useState(false)
    const copyIcon = toAbsoluteUrl('copy_image.png');

    useEffect(() => {
        getDomainSetting()
    }, [])

    const getDomainSetting = () => {
        SettingsService.getVerifyDomainSetting(entity_id)
            .then((res: any) => {
                setDomain(res?.data?.response?.fleet_domain)
                setDomainConfig(res?.data?.response)
            })
            .catch((err: any) => console.log('err 24', err?.response?.data?.message))
    }

    const setVerifyDomain = () => {
        setLoader(true)
        const data = {
            fleet_domain: domain
        }
        SettingsService.saveVerifyDomain(entity_id, data)
            .then((res: any) => {
                toast.success(res?.data?.message)
                getDomainSetting()
            })
            .catch((err: any) => toast.error(err?.response?.data?.message))
            .finally(() => setLoader(false))
    }

    const copyText = (event: any) => {
        navigator.clipboard.writeText(event.target.parentElement.innerText);
        toast.success('Copied to clipboard')
    };

    return (
        <Fragment>
            <Card className='mb-5'>
                <CardHeader>
                    <CardTitle className='dropdown-header'>Domain Configuration</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <CardText className='dropdown-sub-header p-0' style={{ fontSize: '1.1rem' }}>Enter these values on your DNS to allow emails to be sent from your domain name</CardText>
                        <div className='d-flex p-0'>
                            <CardTitle className='dropdown-sub-header me-3 p-0' style={{fontSize: '1.1rem'}}>Custom Domain</CardTitle>
                            <div className="mb-2 d-flex align-items-center">
                                {domainConfig?.fleet_domain_status == 'verified' && 
                                    <span className="copy-label copy-label-success"><i className="fas fa-check-circle me-2" style={{color: 'white'}}></i> 
                                        {domainConfig?.fleet_domain_status}
                                    </span>
                                }
                                {domainConfig?.fleet_domain_status != null && domainConfig?.fleet_domain_status != 'verified' &&
                                    <span className="copy-label copy-label-default"><i className="fas fa-times-circle me-2" style={{color: 'white'}}></i> 
                                        {domainConfig?.fleet_domain_status}
                                    </span>
                                }
                            </div>
                        </div>
                        <Input type='text'
                            placeholder='Enter domain'
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                        />
                    </Row>
                    <Row className='mt-10'>
                        <div className='d-flex justify-content-end'>
                            <Button type="button" color="primary" className='mx-2 btn btn-sm' disabled={loader} onClick={() => setVerifyDomain()}>
                                {loader ? <Spinner size='sm' /> : <span className='align-middle d-sm-inline-block d-none'>Verify</span>}
                            </Button>
                        </div>
                    </Row>
                    <Row className='mt-10'>
                        <Card>
                            <CardHeader className='px-5' style={{minHeight: '50px'}}>
                                <CardTitle className='dropdown-sub-header' style={{ fontSize: '1.1rem' }}>
                                    1. Add DNS records for sending
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='p-5'>
                                <Table className='jobs-table'>
                                    <thead className='sticky-header'>
                                        <tr className="table-active" style={{fontWeight: '500'}}>
                                            <th className='dropdown-sub-header ps-3' style={{ fontSize: '1rem' }}>TYPE</th>
                                            <th className='dropdown-sub-header' style={{ fontSize: '1rem' }}>HOSTNAME</th>
                                            <th className='dropdown-sub-header' style={{ fontSize: '1rem' }}>ENTER THIS VALUE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domainConfig?.dns_records?.dkim?.map((d: any, i: any) => (
                                            <tr key={i} style={{borderBottom: '1px solid rgba(0, 0, 0, .12)'}}>
                                                <td className='ps-3'>{d?.type}</td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {d?.name}
                                                </td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {d?.value}
                                                </td>
                                            </tr>
                                        ))}
                                        {domainConfig?.dns_records?.spf && (
                                            <tr>
                                                <td className='ps-3'>{domainConfig?.dns_records?.spf?.type}</td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.spf?.name}
                                                </td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.spf?.value}
                                                </td>
                                            </tr>
                                        )}
                                        {domainConfig?.dns_records?.dmarc && (
                                            <tr>
                                                <td className='ps-3'>{domainConfig?.dns_records?.dmarc?.type}</td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.dmarc?.name}
                                                </td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.dmarc?.value}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card className='my-5'>
                            <CardHeader className='px-5' style={{minHeight: '50px'}}>
                                <CardTitle className='dropdown-sub-header' style={{ fontSize: '1.1rem' }}>
                                    2. Add DNS records for tracking
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='p-5'>   
                                <Table className='jobs-table'>
                                    <thead className='sticky-header'>
                                        <tr className="table-active">
                                            <th className='dropdown-sub-header ps-3' style={{ fontSize: '1rem' }}>TYPE</th>
                                            <th className='dropdown-sub-header' style={{ fontSize: '1rem' }}>HOSTNAME</th>
                                            <th className='dropdown-sub-header' style={{ fontSize: '1rem' }}>ENTER THIS VALUE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domainConfig?.dns_records?.txt && (
                                            <tr>
                                                <td className='ps-3'>{domainConfig?.dns_records?.txt?.type}</td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.txt?.name}
                                                </td>
                                                <td>
                                                    <img src={copyIcon} alt="copy" className="me-2 copy-image cursor-pointer" onClick={copyText} />
                                                    {domainConfig?.dns_records?.txt?.value}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader className='px-5' style={{minHeight: '50px'}}>
                                <CardTitle className='dropdown-sub-header' style={{ fontSize: '1.1rem' }}>
                                    3. Wait for your domain to verify
                                    <div className="d-flex align-items-center ms-3">
                                        {domainConfig?.fleet_domain_dkim_status == 'verified' && 
                                            <span className="copy-label copy-label-success"><i className="fas fa-check-circle me-2" style={{color: 'white'}}></i> 
                                                {domainConfig?.fleet_domain_dkim_status}
                                            </span>
                                        }
                                        {domainConfig?.fleet_domain_dkim_status != null && domainConfig?.fleet_domain_dkim_status != 'verified' &&
                                            <span className="copy-label copy-label-default"><i className="fas fa-times-circle me-2" style={{color: 'white'}}></i> 
                                                {domainConfig?.fleet_domain_dkim_status}
                                            </span>
                                        }
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='p-5'>   
                                <CardTitle className='dropdown-sub-header'  style={{ fontSize: '1.1rem' }}>
                                    Once you make the above DNS changes, it can take 24-48 hours for those changes to propagate.
                                    We will email you to let you know once your domain is verified.
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Row>
                </CardBody>
            </Card >
        </Fragment >
    )
}

export default DomainConfiguration