import { Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap'
import UILoader from './Ui-loader'
import ComponentSpinner from '../apps/user-management/users-list/components/loading/Loading-spinner'

function HistoryModal({ loader, openHistoryModal, setOpenHistoryModal, title, tableHearder, keys, width, historyData, clickEvent, openEmail }) {

    return (
        <Modal isOpen={openHistoryModal} toggle={() => setOpenHistoryModal()} className="modal-dialog-centered modal-lg">
            <UILoader blocking={loader === 'booking_history'} loader={<ComponentSpinner />} overlayColor='rgba(245, 245, 240, .1)' className={loader === 'booking_history' ? 'loader-blur' : ''}>
                <ModalHeader className='d-flex justify-content-center'>{title}</ModalHeader>
                <ModalBody>
                    <div id='booking_table' className='history-table-style'>
                        <Table className='history-table'>
                            <thead className='sticky-header'>
                                <tr style={{ fontWeight: '600' }}>
                                    {tableHearder?.map((item: any, index: any) => {
                                        return (
                                            <th className='ps-3' key={index} style={{ maxWidth: width[index] }}>
                                                {item}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {historyData?.map((item: any, i: any) => {
                                    return (
                                        <tr key={i} style={{ borderBottom: '1px solid rgba(0, 0, 0, .12)' }}>
                                            {tableHearder?.map((sub: any, index: any) => {
                                                return (
                                                    <td style={{ maxWidth: width[index] }} key={index} className={(clickEvent[index] && item?.email_id) && 'cursor-pointer'} onClick={() => (clickEvent[index] && item?.email_id) ? openEmail(item) : null}>
                                                        <span>{item[keys[index]]}</span>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {historyData?.length === 0 ?
                            <p className='d-flex justify-content-center'>There are no History to display</p>
                            : ''
                        }
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button size='sm' type="button" color="secondary" onClick={() => setOpenHistoryModal(false)}>
                            Close
                        </Button>
                    </div>
                </ModalBody>
            </UILoader>
        </Modal>
    )
}

export default HistoryModal